import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fillData } from "redux/actions"

const CompactheidCard = ({ dataResposne, gridLayout }) => {
  const data = useSelector((state) => state.data)
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(data?.Compactheid.id)
  const [selectedTitle, setSelectedTitle] = useState(data?.Compactheid.title)

  const handleOptionChange = (information) => {
    if (selected === information.id) {
      return
    } else {
      setSelected(information.id)
      setSelectedTitle(information.title)
      dispatch(fillData({ ...data, Compactheid: information }))
    }
  }
  return (
    <div
      className={`flex ${
        gridLayout ? "grid grid-cols-3 grid-rows-3 gap-4" : ""
      }`}
    >
      {dataResposne &&
        dataResposne.map((information, index) => (
          <div
            onClick={() => handleOptionChange(information)}
            key={information.id}
            className="flex-row justify-between w-full"
          >
            <div
              className={`${
                selectedTitle !== information.title
                  ? "mt-4 w-40  rounded-sm border-red-100 border-2 cursor-pointer"
                  : "mt-4 w-40  rounded-sm border-lightBlue border-2 cursor-pointer"
              }`}
            >
              <div
                className="h-16 w-8 ml-auto mr-auto"
                style={{ position: "relative" }}
              >
                <img
                  src={information.image}
                  alt={information.title}
                  className="ml-auto mr-auto pt-2"
                  style={{ position: "absolute", bottom: 0 }}
                />
              </div>
              <div className="mt-8 flex flex-col">
                <h4
                  className={`${
                    selectedTitle !== information.title
                      ? "justify-center text-center font-mulish font-bold"
                      : "justify-center text-center font-mulish font-bold text-lightBlue"
                  }`}
                >
                  {information.title}
                </h4>
                <h4
                  style={{ fontSize: 14 }}
                  className="justify-center text-center font-mulish font-normal"
                >
                  {information.subtitle}
                </h4>
                <div className="flex justify-center mt-2 mb-1">
                  <input
                    type="radio"
                    className="rounded-full form-radio h-5 w-5"
                    value={information.id}
                    checked={selected === information.id}
                    onChange={() => handleOptionChange(information)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default CompactheidCard

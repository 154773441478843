export const glaspercentage = [
  {
    id: 0,
    value: "30%",
    values: { percentage: 0.3 },
  },
  {
    id: 1,
    value: "40%",
    values: { percentage: 0.4 },
  },
  {
    id: 2,
    value: "50%",
    values: { percentage: 0.5 },
  },
  {
    id: 3,
    value: "60%",
    values: { percentage: 0.6 },
  },
  {
    id: 4,
    value: "70%",
    values: { percentage: 0.7 },
  },
]
export const glas = [
  {
    id: 0,
    value: "Enkelglas",
    secondaryValue: "U = 5,4",
    values: {
      transglasRel: 124.2,
    },
  },
  {
    id: 1,
    value: "Dubbelglas",
    secondaryValue: "U = 3,4",
    values: {
      transglasRel: 78.2,
    },
  },
  {
    id: 2,
    value: "HR",
    secondaryValue: "U = 2,2",
    values: {
      transglasRel: 50.6,
    },
  },
  {
    id: 3,
    value: "HR++",
    secondaryValue: "U = 1,6",
    values: {
      transglasRel: 36.8,
    },
  },
  {
    id: 4,
    value: "Triple",
    secondaryValue: "U = 1,3",
    values: {
      transglasRel: 32.2,
    },
  },
  {
    id: 5,
    value: "Passief",
    secondaryValue: "U = 1,0",
    values: { transglasRel: 23.0 },
  },
]

export const isolatie = [
  {
    id: 0,
    value: "Ongeïsoleerd",
    secondaryValue: "Rc = 0,4",
    values: { transwallRel: 79.31 },
  },
  {
    id: 1,
    value: "Slecht",
    secondaryValue: "Rc = 1,3",
    values: { transwallRel: 24.4 },
  },
  {
    id: 2,
    value: "Gemiddeld",
    secondaryValue: "Rc = 2,5",
    values: { transwallRel: 12.69 },
  },
  {
    id: 3,
    value: "Goed",
    secondaryValue: "Rc = 3,5",
    values: { transwallRel: 9.06 },
  },
  {
    id: 4,
    value: "Nieuwbouw",
    secondaryValue: "Rc = 4,7",
    values: { transwallRel: 6.75 },
  },
  {
    id: 5,
    value: "Passief",
    secondaryValue: "Rc = 6,0",
    values: { transwallRel: 5.29 },
  },
]

export const luchtdichtheid = [
  {
    id: 0,
    value: "Zeer Slecht",
    secondaryValue: "> 1,0",
    values: {
      inf3_6: 0.00118,
      inf6_12: 0.00151,
      inf12_20: 0.00189,
      inf20_30: 0.00232,
      inf_30: 0.00273,
    },
  },
  {
    id: 1,
    value: "Slecht",
    secondaryValue: "0,8 tot 1,0",
    values: {
      inf3_6: 0.00109,
      inf6_12: 0.00138,
      inf12_20: 0.00175,
      inf20_30: 0.00213,
      inf_30: 0.00251,
    },
  },
  {
    id: 2,
    value: "Matig",
    secondaryValue: "0,6 tot 0,8",
    values: {
      inf3_6: 0.00088,
      inf6_12: 0.00111,
      inf12_20: 0.0014,
      inf20_30: 0.00172,
      inf_30: 0.002,
    },
  },
  {
    id: 3,
    value: "Gemiddeld",
    secondaryValue: "0,4 tot 0,6",
    values: {
      inf3_6: 0.00064,
      inf6_12: 0.00082,
      inf12_20: 0.00103,
      inf20_30: 0.00126,
      inf_30: 0.00149,
    },
  },
  {
    id: 4,
    value: "Goed",
    secondaryValue: "0,2 tot 0,4",
    values: {
      inf3_6: 0.00039,
      inf6_12: 0.0005,
      inf12_20: 0.00063,
      inf20_30: 0.00077,
      inf_30: 0.00092,
    },
  },
  {
    id: 5,
    value: "Uitstekend",
    secondaryValue: "< 0,2",
    values: {
      inf3_6: 0.00026,
      inf6_12: 0.00034,
      inf12_20: 0.00043,
      inf20_30: 0.00051,
      inf_30: 0.00062,
    },
  },
]

export const gebouwhoogte = [
  {
    id: 0,
    value: "3 - 6 m",
    values: {
      ZeerSlecht: 0.00118,
      Slecht: 0.00109,
      Matig: 0.00088,
      Gemiddeld: 0.00064,
      Goed: 0.00039,
      Uitstekend: 0.00026,
    },
  },
  {
    id: 1,
    value: "6 - 12 m",
    values: {
      ZeerSlecht: 0.00151,
      Slecht: 0.00138,
      Matig: 0.00111,
      Gemiddeld: 0.00082,
      Goed: 0.0005,
      Uitstekend: 0.00034,
    },
  },
  {
    id: 2,
    value: "12 - 20 m",
    values: {
      ZeerSlecht: 0.00189,
      Slecht: 0.00175,
      Matig: 0.0014,
      Gemiddeld: 0.00103,
      Goed: 0.00063,
      Uitstekend: 0.00043,
    },
  },
  {
    id: 3,
    value: "20 - 30 m",
    values: {
      ZeerSlecht: 0.00232,
      Slecht: 0.00213,
      Matig: 0.00172,
      Gemiddeld: 0.00126,
      Goed: 0.00077,
      Uitstekend: 0.00051,
    },
  },
  {
    id: 4,
    value: "> 30 m",
    values: {
      ZeerSlecht: 0.00273,
      Slecht: 0.00251,
      Matig: 0.002,
      Gemiddeld: 0.00149,
      Goed: 0.00092,
      Uitstekend: 0.00062,
    },
  },
]

export const zonwering = [
  { id: 0, text: "Nee", values: { zta: 0.55 } },
  { id: 1, text: "Ja", values: { zta: 0.3 } },
]

export const glasZonwerend = [
  { id: 1, text: "Nee" },
  { id: 0, text: "Ja" },
]

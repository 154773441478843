import React from "react"
import MediumHeadline from "components/MediumHeadline"
import WinterThermalBalanceTable from "components/Tables/WinterThermalBalanceTable"
import SummerThermalBalanceTable from "components/Tables/SummerThermalBalanceTable"

const Resultaat = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 pb-16">
      <div className="flex flex-col gap-16">
        <MediumHeadline
          text="Resultaat"
          subtext="Je hebt alle stappen doorlopen. Aan de rechterzijde van de pagina zie je de definitieve resultaten voor jouw kantoorvertrek. Voldoet jouw ruimte nog niet aan de gewenste comfortklasse? Ga dan terug naar de vorige stappen om enkele uitgangspunten aan te passen. Je blijft de actuele resultaten rechts in beeld zien."
          shortText="Je hebt alle stappen doorlopen. Aan de rechterzijde van de pagina zie je de definitieve resultaten voor jouw kantoorvertrek. Voldoet jouw ruimte nog niet aan de gewenste comfortklasse? Ga dan terug naar de vorige stappen om enkele uitgangspunten aan te passen. Je blijft de actuele resultaten rechts in beeld zien."
        />
        <div className="flex flex-wrap justify-center gap-16">
          <div className="w-full flex-1">
            <WinterThermalBalanceTable />
          </div>
          <div className="w-full flex-1">
            <SummerThermalBalanceTable />
          </div>
        </div>

        <div>
          <h3 className="text-h4 font-bold text-darkBlue">Disclaimer</h3>
          <p>
            De resultaten in deze tool zijn indicatief en zijn derhalve niet
            geschikt om energieprestatieberekeningen van kantoorgebouwen te
            vervangen.
          </p>
        </div>

        <div className="p-4 bg-lila rounded-lg">
          <h3 className="text-h4 font-bold text-darkBlue">
            Heb je een vraag of suggestie?
          </h3>
          <p>
            Schroom niet en neem contact met ons op. Stuur een email naar:
            info@tvvl.nl
          </p>
        </div>
      </div>
    </div>
  )
}

export default Resultaat

import Space1 from "assets/images/space-1.svg"
import Space2 from "assets/images/space-2.svg"
import Space3 from "assets/images/space-3.svg"

import NordWest from "assets/images/nord-west.svg"
import Geen from "assets/images/geen.svg"

export const spaces = [
  {
    name: "Hoekvertrek",
    selected: false,
    image: Space1,
    wall1: 1,
    wall2: 1,
    disabled: false,
  },
  {
    name: "Tussenvertrek",
    selected: true,
    image: Space2,
    wall1: 1,
    wall2: 0,
    disabled: false,
  },
  {
    name: "Inpandig",
    selected: false,
    image: Space3,
    wall1: 0,
    wall2: 0,
    disabled: false,
  },
]

export const orientations = [
  {
    name: "Noord-West",
    selected: false,
    image: NordWest,
    cool: 338,
    reductieHoekvertrek: 28,
    redfactorblinds: 0.51,
    reductionA: 0.079,
    reductionB: 0.158,
    reductionC: 0.237,
    disabled: false,
  },
  {
    name: "Noord",
    selected: false,
    image: NordWest,
    rotate: 45,
    cool: 132,
    reductieHoekvertrek: -0.53,
    redfactorblinds: 0.13,
    reductionA: 0.032,
    reductionB: 0.064,
    reductionC: 0.096,
    disabled: false,
  },
  {
    name: "Noord-Oost",
    selected: false,
    image: NordWest,
    rotate: 90,
    cool: 261,
    reductieHoekvertrek: 0.05,
    redfactorblinds: 0.33,
    reductionA: 0.059,
    reductionB: 0.118,
    reductionC: 0.177,
    disabled: false,
  },
  {
    name: "West",
    selected: false,
    image: NordWest,
    rotate: 315,
    cool: 461,
    reductieHoekvertrek: 0.29,
    redfactorblinds: 0.57,
    reductionA: 0.061,
    reductionB: 0.122,
    reductionC: 0.183,
    disabled: false,
  },
  {
    name: "Geen oriëntatie",
    selected: false,
    image: Geen,
    class: "I",
    disabled: true,
  },
  {
    name: "Oost",
    selected: false,
    image: NordWest,
    rotate: 135,
    cool: 429,
    reductieHoekvertrek: 0.28,
    redfactorblinds: 0.46,
    reductionA: 0.07,
    reductionB: 0.14,
    reductionC: 0.21,
    disabled: false,
  },
  {
    name: "Zuid-West",
    selected: true,
    image: NordWest,
    rotate: 270,
    cool: 469,
    reductieHoekvertrek: 0.24,
    redfactorblinds: 0.39,
    reductionA: 0.074,
    reductionB: 0.148,
    reductionC: 0.222,
    disabled: false,
  },
  {
    name: "Zuid",
    selected: false,
    image: NordWest,
    rotate: 225,
    cool: 468,
    reductieHoekvertrek: 0.24,
    redfactorblinds: 0.39,
    reductionA: 0.073,
    reductionB: 0.146,
    reductionC: 0.219,
    disabled: false,
  },
  {
    name: "Zuid-Oost",
    selected: false,
    image: NordWest,
    rotate: 180,
    cool: 453,
    reductieHoekvertrek: 0.26,
    redfactorblinds: 0.37,
    reductionA: 0.077,
    reductionB: 0.154,
    reductionC: 0.231,
    disabled: false,
  },
]

export function calculateVentilatievoud(floorArea, floorHeight, factor) {
  const result = floorArea * floorHeight * factor

  return result
}

export function calculateVentilation(
  selectedVentilatievoud,
  floorArea,
  ventilationFactor
) {
  const result = (selectedVentilatievoud * ventilationFactor) / (3 * floorArea)

  return result
}

//plafondhoogte
export function calculateAirHeatingPlafond(selectedVent, floorArea) {
  const result = (selectedVent * 3 * 12) / (3 * floorArea)

  return result
}
export function calculateLocalCoolingPlafond(
  selectedVentilatievoud,
  floorArea,
  ventilationFactor
) {
  const result =
    (selectedVentilatievoud * 2 * ventilationFactor) / (3 * floorArea)

  return result
}

//VAV + naverwarming
export function calculateAirHeatingVAV(selectedVentilation, floorArea) {
  const result = (selectedVentilation * 12) / (3 * floorArea)

  return result
}

//Fancoil-units
export function calculateAirHeatingFancil(floorHeight) {
  const result = (12 * floorHeight * 12) / 3

  return result
}
export function calculateLocalFancil(
  floorHeight,
  selectedVentilation,
  floorArea,
  multiplier
) {
  const ventilationValue = (selectedVentilation * multiplier) / (3 * floorArea)

  const result = (12 * floorHeight * multiplier) / 3 - ventilationValue

  return result
}

import React, { useState, useMemo, useCallback } from "react"
import { useSelector } from "react-redux"
import InfoIcon from "assets/images/info.png"
import InfoModal from "components/InfoModal"
import { getGlassArea } from "calculationFunctions/wkbCalculations/gevelCalculations"
import { getPersonsheat } from "calculationFunctions/wkbCalculations/gebruikCalculations"

const SummerThermalBalanceTable = () => {
  const wkbData = useSelector((state) => state.wkbData)
  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalText, setInfoModalText] = useState("")
  const [infoModalTitle, setInfoModalTitle] = useState("")

  // Memoizing derived data for performance optimization
  const glassArea = useMemo(() => {
    if (
      !wkbData?.width ||
      !wkbData?.ceilingHeight ||
      !wkbData?.space ||
      !wkbData?.Glaspercentage?.values?.percentage ||
      !wkbData?.depth
    )
      return 0
    return getGlassArea(
      wkbData.width,
      wkbData.ceilingHeight,
      wkbData.space,
      wkbData.Glaspercentage?.values?.percentage,
      wkbData.depth
    )
  }, [
    wkbData?.width,
    wkbData?.ceilingHeight,
    wkbData?.space,
    wkbData?.Glaspercentage?.values?.percentage,
    wkbData?.depth,
  ])

  const persons = useMemo(() => {
    if (!wkbData?.persons || !wkbData?.floorArea) return 0
    return getPersonsheat(wkbData.persons, wkbData.floorArea)
  }, [wkbData?.persons, wkbData?.floorArea])

  const externeKoellastClass = useMemo(() => {
    const isBlinds = wkbData?.GlasZonwerend?.text === "Ja"
    const getBlindsData = () => {
      if (isBlinds)
        return {
          ztaBlinds: 1,
          reductionBlinds: 1 * wkbData?.orientation?.redfactorblinds,
          cornerRoom: wkbData.space.name === "Hoekvertrek" ? 1 : 0,
          reductionCorner:
            wkbData.space.name === "Hoekvertrek"
              ? 1 * wkbData?.orientation?.reductieHoekvertrek
              : 0,
        }
      if (!isBlinds) {
        return {
          ztaBlinds: 0,
          reductionBlinds: 0,
          cornerRoom: wkbData.space.name === "Hoekvertrek" ? 1 : 0,
          reductionCorner:
            wkbData.space.name === "Hoekvertrek"
              ? 1 * wkbData?.orientation?.reductieHoekvertrek
              : 0,
        }
      }
    }

    const reductionBlindsperglass =
      wkbData.orientation.cool *
      (1 - getBlindsData().reductionBlinds) *
      (1 - getBlindsData().reductionCorner)

    return (
      (glassArea * wkbData?.Zonwering?.values?.zta * reductionBlindsperglass) /
      wkbData.floorArea
    )
  }, [
    glassArea,
    wkbData?.orientation?.cool,
    wkbData?.GlasZonwerend?.text,
    wkbData?.Zonwering?.values?.zta,
    wkbData?.floorArea,
    wkbData?.orientation?.redfactorblinds,
    wkbData?.orientation?.reductieHoekvertrek,
    wkbData?.space?.name,
  ])

  const interneKoellast = useMemo(() => {
    if (!persons || !wkbData?.Verlichting?.values?.ventilationflow) return 0

    return persons + wkbData.Verlichting.values.ventilationflow
  }, [persons, wkbData?.Verlichting?.values?.ventilationflow])

  const totaleKoellastClass = useMemo(() => {
    const createTotalKoellast = (reduction) =>
      externeKoellastClass + interneKoellast + reduction

    return {
      A: createTotalKoellast(wkbData?.orientation?.reductionA || 0),
      B: createTotalKoellast(wkbData?.orientation?.reductionB || 0),
      C: createTotalKoellast(wkbData?.orientation?.reductionC || 0),
    }
  }, [
    externeKoellastClass,
    interneKoellast,
    wkbData?.orientation?.reductionA,
    wkbData?.orientation?.reductionB,
    wkbData?.orientation?.reductionC,
  ])

  const coolingData = useMemo(() => {
    const getCoolingValues = (classType) => {
      const climateBox = wkbData?.climateBoxes?.values?.cooling?.[classType]

      if (!climateBox) return { vent: 0, local: 0 }
      return {
        vent: climateBox.ventilation || 0,
        local: climateBox.local || 0,
      }
    }

    const balanceCalc = (cooling, totaleKoellast) =>
      cooling.local + cooling.vent - totaleKoellast

    const classA = getCoolingValues("classA")
    const classB = getCoolingValues("classB")
    const classC = getCoolingValues("classC")

    return {
      A: {
        vent: classA.vent,
        local: classA.local,
        balance: balanceCalc(classA, totaleKoellastClass.A),
      },
      B: {
        vent: classB.vent,
        local: classB.local,
        balance: balanceCalc(classB, totaleKoellastClass.B),
      },
      C: {
        vent: classC.vent,
        local: classC.local,
        balance: balanceCalc(classC, totaleKoellastClass.C),
      },
    }
  }, [totaleKoellastClass, wkbData?.climateBoxes?.values])

  const data = [
    {
      category: "Externe koellast",
      comfortA: externeKoellastClass,
      comfortB: externeKoellastClass,
      comfortC: externeKoellastClass,
    },
    {
      category: "Interne koellast",
      comfortA: interneKoellast,
      comfortB: interneKoellast,
      comfortC: interneKoellast,
    },
    {
      category: "Reductie",
      comfortA: externeKoellastClass * wkbData?.orientation?.reductionA,
      comfortB: externeKoellastClass * wkbData?.orientation?.reductionB,
      comfortC: externeKoellastClass * wkbData?.orientation?.reductionC,
    },
    {
      category: "Totale koellast",
      comfortA:
        externeKoellastClass +
        interneKoellast -
        externeKoellastClass * wkbData?.orientation?.reductionA,
      comfortB:
        externeKoellastClass +
        interneKoellast -
        externeKoellastClass * wkbData?.orientation?.reductionB,
      comfortC:
        externeKoellastClass +
        interneKoellast -
        externeKoellastClass * wkbData?.orientation?.reductionC,
    },
    {
      category: "Koelling ventilatielucht",
      comfortA: coolingData.A.vent,
      comfortB: coolingData.B.vent,
      comfortC: coolingData.C.vent,
    },
    {
      category: "Lokale koeling",
      comfortA: coolingData.A.local,
      comfortB: coolingData.B.local,
      comfortC: coolingData.C.local,
    },
    {
      category: "Balans",
      comfortA:
        coolingData.A.vent +
        coolingData.A.local -
        (externeKoellastClass +
          interneKoellast -
          externeKoellastClass * wkbData?.orientation?.reductionA),
      comfortB:
        coolingData.B.vent +
        coolingData.B.local -
        (externeKoellastClass +
          interneKoellast -
          externeKoellastClass * wkbData?.orientation?.reductionB),
      comfortC:
        coolingData.C.vent +
        coolingData.C.local -
        (externeKoellastClass +
          interneKoellast -
          externeKoellastClass * wkbData?.orientation?.reductionC),
      isBalance: true,
    },
  ]

  const handleInfoClick = useCallback(() => {
    setInfoModalText(
      "In deze tabel wordt een voorspelling van de koellast per comfortklasse \n" +
        "weergegeven. Per comfortklasse geldt een operatieve temperatuur in de \n" +
        "leefzone van 27 °C voor klasse C, 26 °C voor klasse B en 25 °C voor klasse A. De\n" +
        "totale koellast wordt vergeleken met het maximale koelvermogen van de \n" +
        "gekozen klimaatinstallatie. Bij een positieve balans kleurt het getal groen en kan\n" +
        "aan de comfortklasse worden voldaan."
    )
    setInfoModalTitle("Zomer warmtebalans (W/m²)")
    setModalOpen(true)
  }, [])

  return (
    <>
      <InfoModal
        open={modalOpen}
        text={infoModalText}
        title={infoModalTitle}
        image={null}
        closeModal={() => setModalOpen(false)}
      />
      <table className="w-full mt-5 mb-10 border-collapse">
        <thead>
          <tr>
            <th colSpan="4" className="bg-lightBlue text-white p-2 text-left">
              <div className="flex items-center">
                <span>Zomer warmtebalans (W/m²)</span>
                <img
                  src={InfoIcon}
                  className="cursor-pointer h-5 ml-2"
                  alt="info"
                  onClick={handleInfoClick}
                />
              </div>
            </th>
          </tr>
          <tr>
            <th className="p-2 text-left text-lightBlue bg-lightGray">
              Comfortklasse
            </th>
            <th className="p-2 text-center bg-classC text-white w-24 md:w-32 lg:w-32">
              C
            </th>
            <th className="p-2 text-center bg-classB text-white w-24 md:w-32 lg:w-32">
              B
            </th>
            <th className="p-2 text-center bg-classA text-white w-24 md:w-32 lg:w-32">
              A
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row, index) => (
            <tr key={index} className={row.isBalance ? "bg-buttonGray" : ""}>
              <td
                className={`p-2 text-left ${
                  row.isBalance
                    ? "text-black"
                    : "bg-lightGray border-t-4 border-white whitespace-nowrap"
                }`}
              >
                {row.category}
              </td>
              <td
                className={`p-2 text-center ${
                  row.isBalance
                    ? row.comfortC > 0
                      ? "text-green-500"
                      : row.comfortC < 0
                      ? "text-red-500"
                      : ""
                    : "bg-lightGray border-t-4 border-white"
                }`}
              >
                {Math.round(row.comfortC)}
              </td>
              <td
                className={`p-2 text-center ${
                  row.isBalance
                    ? row.comfortB > 0
                      ? "text-green-500"
                      : row.comfortB < 0
                      ? "text-red-500"
                      : ""
                    : "bg-lightGray border-t-4 border-white"
                }`}
              >
                {Math.round(row.comfortB)}
              </td>
              <td
                className={`p-2 text-center ${
                  row.isBalance
                    ? row.comfortA > 0
                      ? "text-green-500"
                      : row.comfortA < 0
                      ? "text-red-500"
                      : ""
                    : "bg-lightGray border-t-4 border-white"
                }`}
              >
                {Math.round(row.comfortA)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default SummerThermalBalanceTable

import React, { useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"

import InfoModal from "components/InfoModal"
import InfoIcon from "assets/images/info.png"
import {
  getGlassArea,
  getWallArea,
} from "calculationFunctions/wkbCalculations/gevelCalculations"
import {
  luchtdichtheid,
  gebouwhoogte,
} from "../../pages/WKBcheck/GevelSection/data"

const WinterThermalBalanceTable = () => {
  const wkbData = useSelector((state) => state.wkbData)
  // Helper function for calculating values related to gebouwhoogte and luchtdichtheid
  const getGebouwhoogteValue = (luchtdichtheidValue, height) => {
    const luchtdichtheidItem = luchtdichtheid.find((item) =>
      luchtdichtheidValue === "Zeer Slecht"
        ? item.value === "Zeer Slecht"
        : item.value === luchtdichtheidValue
    )

    const gebouwhoogteItem = gebouwhoogte.find((item) => item.value === height)

    if (!luchtdichtheidItem || !gebouwhoogteItem) return null

    const lookupKey =
      luchtdichtheidValue === "Zeer Slecht" ? "ZeerSlecht" : luchtdichtheidValue

    return gebouwhoogteItem.values[lookupKey]
  }

  const glassArea = useMemo(
    () =>
      getGlassArea(
        wkbData.width,
        wkbData.ceilingHeight,
        wkbData.space,
        wkbData.Glaspercentage?.values?.percentage,
        wkbData.depth
      ),
    [
      wkbData.width,
      wkbData.ceilingHeight,
      wkbData.space,
      wkbData.Glaspercentage,
      wkbData.depth,
    ]
  )

  const wallArea = useMemo(
    () =>
      getWallArea(
        wkbData.width,
        wkbData.ceilingHeight,
        wkbData.space,
        wkbData.Glaspercentage?.values?.percentage,
        wkbData.depth
      ),
    [
      wkbData.width,
      wkbData.ceilingHeight,
      wkbData.space,
      wkbData.Glaspercentage,
      wkbData.depth,
    ]
  )

  const transglasREL = wkbData?.Glas?.values?.transglasRel
  const transwallREL = wkbData.Isolatie?.values?.transwallRel

  const transglasABS = glassArea * transglasREL
  const transwallABS = wallArea * transwallREL

  const infiltration = useMemo(
    () =>
      getGebouwhoogteValue(
        wkbData.Luchtdichtheid.value,
        wkbData.Gebouwhoogte.value
      ),
    [wkbData.Luchtdichtheid, wkbData.Gebouwhoogte]
  )

  const infiltrationABS = useMemo(
    () => infiltration * 1200 * 29.5 * (glassArea + wallArea),
    [infiltration, glassArea, wallArea]
  )

  const calculateClasses = (classA, multiplier) => (classA * multiplier) / 29.5

  const transmissieClassA = (transglasABS + transwallABS) / wkbData.floorArea
  const transmissieClassB = calculateClasses(transmissieClassA, 28.5)
  const transmissieClassC = calculateClasses(transmissieClassA, 27.5)

  const infiltratieClassA = infiltrationABS / wkbData.floorArea
  const infiltratieClassB = calculateClasses(infiltratieClassA, 28.5)
  const infiltratieClassC = calculateClasses(infiltratieClassA, 27.5)

  const ventilatieClassA =
    wkbData.Ventilatievoud.values.vent / wkbData.floorArea
  const ventilatieClassB = ventilatieClassA
  const ventilatieClassC = ventilatieClassA

  // Sum of heat losses
  const sumOfHeatlossesClassA =
    transmissieClassA + 5 + infiltratieClassA + ventilatieClassA
  const sumOfHeatlossesClassB =
    transmissieClassB + 5 + infiltratieClassB + ventilatieClassB
  const sumOfHeatlossesClassC =
    transmissieClassC + 5 + infiltratieClassC + ventilatieClassC

  // Local heating values
  const localA =
    wkbData.climateBoxes?.values?.heating?.classA?.radiation +
    wkbData.climateBoxes?.values?.heating?.classA?.air
  const localB =
    wkbData.climateBoxes?.values?.heating?.classB?.radiation +
    wkbData.climateBoxes?.values?.heating?.classB?.air
  const localC =
    wkbData.climateBoxes?.values?.heating?.classC?.radiation +
    wkbData.climateBoxes?.values?.heating?.classC?.air

  const balanceA = localA - sumOfHeatlossesClassA
  const balanceB = localB - sumOfHeatlossesClassB
  const balanceC = localC - sumOfHeatlossesClassC

  const data = [
    {
      category: "Transmissie",
      comfortC: transmissieClassC || 0,
      comfortB: transmissieClassB || 0,
      comfortA: transmissieClassA || 0,
    },
    { category: "Opwarmtoeslag", comfortC: 5, comfortB: 5, comfortA: 5 },
    {
      category: "Infiltratie",
      comfortC: infiltratieClassC || 0,
      comfortB: infiltratieClassB || 0,
      comfortA: infiltratieClassA || 0,
    },
    {
      category: "Ventilatie",
      comfortC: ventilatieClassC || 0,
      comfortB: ventilatieClassB || 0,
      comfortA: ventilatieClassA || 0,
    },
    {
      category: "Totaal warmteverlies",
      comfortC: sumOfHeatlossesClassC || 0,
      comfortB: sumOfHeatlossesClassB || 0,
      comfortA: sumOfHeatlossesClassA || 0,
    },
    {
      category: "Lokale verwarming",
      comfortC: localC || 0,
      comfortB: localB || 0,
      comfortA: localA || 0,
    },
    {
      category: "Balans",
      comfortC: balanceC || 0,
      comfortB: balanceB || 0,
      comfortA: balanceA || 0,
      isBalance: true,
    },
  ]

  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalText, setInfoModalText] = useState("")
  const [infoModalTitle, setInfoModalTitle] = useState("")

  return (
    <>
      <InfoModal
        open={modalOpen}
        text={infoModalText}
        title={infoModalTitle}
        image={null}
        closeModal={() => setModalOpen(false)}
      />
      <table className="w-full mt-5 border-collapse">
        <thead>
          <tr>
            <th colSpan="4" className="bg-lightBlue text-white p-2 text-left">
              <div className="flex items-center">
                <span>Winter warmtebalans (W/m²)</span>
                <img
                  src={InfoIcon}
                  className="cursor-pointer h-5 ml-2"
                  alt="info"
                  onClick={() => {
                    setInfoModalText(
                      "In deze tabel wordt een voorspelling van het warmteverlies per comfortklasse \n" +
                        "weergegeven. Per comfortklasse geldt een operatieve temperatuur in de \n" +
                        "leefzone van 19 °C voor klasse C, 20 °C voor klasse B en 21 °C voor klasse A. \n" +
                        "Het totale warmteverlies wordt vergeleken met het maximale \n" +
                        "verwarmingsvermogen van de gekozen klimaatinstallatie. Bij een positieve \n" +
                        "balans kleurt het getal groen en kan aan de comfortklasse worden voldaan."
                    )
                    setInfoModalTitle("Winter warmtebalans (W/m²)")
                    setModalOpen(true)
                  }}
                />
              </div>
            </th>
          </tr>
          <tr>
            <th className="p-2 text-left text-lightBlue bg-lightGray">
              Comfortklasse
            </th>
            <th className="p-2 text-center bg-classC text-white w-24 md:w-32 lg:w-32">
              C
            </th>
            <th className="p-2 text-center bg-classB text-white w-24 md:w-32 lg:w-32">
              B
            </th>
            <th className="p-2 text-center bg-classA text-white w-24 md:w-32 lg:w-32">
              A
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((row, index) => (
              <tr key={index} className={row.isBalance ? "bg-buttonGray" : ""}>
                <td
                  className={`p-2 text-left ${
                    row.isBalance
                      ? "text-black font-500"
                      : "bg-lightGray border-t-4 border-white whitespace-nowrap"
                  }`}
                >
                  {row.category}
                </td>
                <td
                  className={`p-2 text-center ${
                    row.isBalance
                      ? row.comfortC > 0
                        ? "text-green-500"
                        : row.comfortC < 0
                        ? "text-red-500"
                        : ""
                      : "bg-lightGray border-t-4 border-white"
                  }`}
                >
                  {Math.round(row.comfortC)}
                </td>
                <td
                  className={`p-2 text-center ${
                    row.isBalance
                      ? row.comfortB > 0
                        ? "text-green-500"
                        : row.comfortB < 0
                        ? "text-red-500"
                        : ""
                      : "bg-lightGray border-t-4 border-white"
                  }`}
                >
                  {Math.round(row.comfortB)}
                </td>
                <td
                  className={`p-2 text-center ${
                    row.isBalance
                      ? row.comfortA > 0
                        ? "text-green-500"
                        : row.comfortA < 0
                        ? "text-red-500"
                        : ""
                      : "bg-lightGray border-t-4 border-white"
                  }`}
                >
                  {Math.round(row.comfortA)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default WinterThermalBalanceTable

import React from "react"

const GeometrieInput = ({
  id,
  label,
  unit,
  value,
  onChange,
  readOnly,
  error,
}) => {
  return (
    <div className="w-44">
      <label className="text-lightBlue font-semibold">{label}</label>
      <div className="relative mt-2 border-2 border-borderColor rounded">
        <input
          id={id}
          type="number"
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          className={`appearance-none text-trueGray-700 font-mulish block w-full py-3 pl-3 ${
            unit ? "pr-12" : "pr-3"
          } leading-tight focus:outline-none ${
            readOnly ? "bg-gray" : "bg-white"
          }`}
        />
        {unit && (
          <span className="absolute font-mulish right-3 top-1/2 transform -translate-y-1/2 text-trueGray-400">
            {unit}
          </span>
        )}
      </div>
      {error && <div className="text-red-500 text-h5 mt-1">{error}</div>}
    </div>
  )
}

export default GeometrieInput

/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"

import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  addOrientationData,
  addHeatingClass,
  addCoolingClass,
} from "redux/actions"

import RoomBox from "components/RoomBox"
import Button from "components/Button"
import Tooltip from "components/Tooltip"

import Space1 from "assets/images/space-1.svg"
import Space2 from "assets/images/space-2.svg"
import Space3 from "assets/images/space-3.svg"

import NordWest from "assets/images/nord-west.svg"
import Geen from "assets/images/geen.svg"
import ArrowRight from "assets/images/arrow-right.svg"
import InfoIcon from "assets/images/info.png"

const RuimteStep = ({ setStep, index, room }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedSpace, setSelectedSpace] = useState("")
  const [space, setSpace] = useState([
    {
      name: "Hoekvertrek",
      selected: false,
      image: Space1,
      class: "A",
      disabled: false,
    },
    {
      name: "Tussenvertrek",
      selected: false,
      image: Space2,
      class: "B",
      disabled: false,
    },
    {
      name: "Inpandig",
      selected: false,
      image: Space3,
      class: "C",
      disabled: false,
    },
  ])
  const [orientation, setOrientations] = useState([
    {
      name: "Noord-West",
      selected: false,
      image: NordWest,
      class: "H",
      disabled: false,
    },
    {
      name: "Noord",
      selected: false,
      image: NordWest,
      rotate: 45,
      class: "A",
      disabled: false,
    },
    {
      name: "Noord-Oost",
      selected: false,
      image: NordWest,
      rotate: 90,
      class: "B",
      disabled: false,
    },
    {
      name: "West",
      selected: false,
      image: NordWest,
      rotate: 315,
      class: "G",
      disabled: false,
    },
    {
      name: "Geen oriëntatie",
      selected: false,
      image: Geen,
      class: "I",
      disabled: false,
    },
    {
      name: "Oost",
      selected: false,
      image: NordWest,
      rotate: 135,
      class: "C",
      disabled: false,
    },
    {
      name: "Zuid-West",
      selected: false,
      image: NordWest,
      rotate: 270,
      class: "F",
      disabled: false,
    },
    {
      name: "Zuid",
      selected: false,
      image: NordWest,
      rotate: 225,
      class: "E",
      disabled: false,
    },
    {
      name: "Zuid-Oost",
      selected: false,
      image: NordWest,
      rotate: 180,
      class: "D",
      disabled: false,
    },
  ])

  const [data, setData] = useState({
    space: "",
    orientation: "",
  })

  const selectSpace = (name) => {
    let spaces = [...space]
    spaces?.forEach((elem) => {
      elem.selected = false
      if (elem.name === name) {
        elem.selected = true
      }
    })
    setSpace([...spaces])
  }
  const selectOrientation = (name) => {
    let spaces = [...orientation]
    spaces?.forEach((elem) => {
      elem.selected = false
      if (elem.name === name) {
        elem.selected = true
      }
    })
    setOrientations([...spaces])
  }

  const checkForm = () => {
    if (
      data.orientation === "" ||
      data.space === "" ||
      data.orientation === undefined ||
      data.space === undefined
    )
      return
    dispatch(addOrientationData(data, index))
    setStep(3)
    history.push(`/binnenklimaat/room-step/3/${index}`)
  }

  const checkForValidCombinations = () => {
    let orientations = [...orientation]
    let spaces = [...space]
    let roomCopy = [...room[0]?.facade]
    let geen = orientation.find((value) => value.name === "Geen oriëntatie")

    var modifiedRoom = roomCopy.filter(
      (value) => value.name !== "Is er buitenzonwering?"
    )
    modifiedRoom.map((elem) => {
      if (elem.value === "n.v.t") {
        spaces.map((elem) => {
          elem.disabled = true
          if (elem.name === "Inpandig") {
            elem.disabled = false
          }
        })
        orientations.map((elem) => (elem.disabled = true))
        geen.disabled = false
      } else {
        spaces.map((elem) => {
          elem.disabled = false
          if (elem.name === "Inpandig") {
            elem.disabled = true
          }
        })
        orientations.map((elem) => (elem.disabled = false))
        geen.disabled = true
      }
    })
    setSpace([...spaces])
    setOrientations([...orientations])
  }

  const deSelecteSpace = () => {
    let orient = [...orientation]
    orient.forEach((elem) => {
      if (elem.selected) elem.selected = false
    })

    setOrientations([...orient])
  }

  useEffect(() => {
    //This is if user comes back to this step after filling it already
    let orient = [...orientation]
    let spc = [...space]
    orient?.forEach((elem) => {
      if (elem.name === room[0]?.orientation) {
        elem.selected = true
      }
    })
    spc?.forEach((elem) => {
      if (elem.name === room[0]?.space) {
        elem.selected = true
      }
    })
    setOrientations([...orient])
    setSpace([...spc])
    setData({ space: room[0]?.space, orientation: room[0]?.space })
  }, [])

  useEffect(() => {
    checkForValidCombinations()
  }, [])
  return (
    <div className="relative">
      <div className="flex items-center">
        <p className="font-bold text-darkBlue text-h4">
          Wat voor soort ruimte betreft het?
        </p>
        <img
          src={InfoIcon}
          className="cursor-pointer ml-5 h-5"
          alt="info"
          data-tip={
            "Voer in of het referentievertrek een hoekvertrek (2 zijden buitengevel), een tussenvertrek (1 zijde buitengevel) of een inpandig vertrek betreft. Bij een inpandig vertrek dient in het invoerveld ‘ Percentage glas in de gevel’ (stap 1, Ruimtes) de waarde 0% glas te worden gekozen."
          }
        />
        <Tooltip />
      </div>
      <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-5 mt-5">
        {space.map((elem, i) => (
          <RoomBox
            title={elem.name}
            key={i}
            selected={elem.selected}
            image={elem.image}
            disabled={elem.disabled}
            onClick={() => {
              if (elem.disabled) return
              deSelecteSpace()
              setSelectedSpace(elem.name)
              selectSpace(elem.name)
              setData({ ...data, space: elem.name })
              dispatch(addHeatingClass(0, elem.class, index))
              dispatch(addCoolingClass(0, elem.class, index))
              dispatch(
                addOrientationData(
                  { space: elem.name, orientation: data.orientation },
                  index
                )
              )
              // console.log("THIS IS THE ROOM DATA", data)
            }}
          />
        ))}
      </div>
      <div className="flex items-center my-8">
        <p className="font-bold text-darkBlue text-h4">
          Wat is de oriëntatie van de gevel?
        </p>
        <img
          src={InfoIcon}
          className="cursor-pointer ml-5 h-5"
          alt="info"
          data-tip="Kies de oriëntatie van de in gevel. De gevel oriëntatie wordt meegenomen in de berekening van het zomercomfort. Bij een hoekvertrek met een buitengevel op het Westen en het Zuiden, kiest u bijvoorbeeld Zuid-West."
        />
        <Tooltip />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        {orientation.map((elem, i) => (
          <RoomBox
            title={elem.name}
            selected={elem.selected}
            image={elem.image}
            disabled={elem.disabled}
            key={i}
            rotate={elem?.rotate}
            onClick={() => {
              if (elem.disabled) return
              selectOrientation(elem.name)
              setData({ ...data, orientation: elem.name })
              dispatch(addCoolingClass(1, elem.class, index))
              dispatch(
                addOrientationData(
                  { space: data.space, orientation: elem.name },
                  index
                )
              )
              // checkForGeenSpace(elem.name, 1, elem.class,)
            }}
          />
        ))}
      </div>
      <div className="mt-10 pb-24">
        <Button
          text="NAAR INSTALLATIE"
          isDark={false}
          icon={ArrowRight}
          onClick={() => {
            checkForm()
          }}
        />
      </div>
    </div>
  )
}

export default RuimteStep

/* eslint-disable no-undef */
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import InfoModal from "components/InfoModal"
import { useHistory } from "react-router-dom"
import { fillData } from "redux/actions"
import Button from "components/Button"
import ArrowRight from "assets/images/arrow-right.svg"
import Tooltip from "components/Tooltip"
import InfoIcon from "assets/images/info.png"
import HorizontalStepper from "components/GebouwSection/HorizontalStepper"
import { changeActiveRoom, toggleIsCalculationDone } from "redux/actions"

const stepItems = [
  {
    id: 0,
    value: "Uitstekend",
    secondaryValue: "vv = 6",
    values: {
      ep1: null,
      ep2: 1.7544,
      ep3: 1.0938,
    },
  },
  {
    id: 1,
    value: "Zeer goed",
    secondaryValue: "vv = 4",
    values: {
      ep1: null,
      ep2: 1.3684,
      ep3: 1.0625,
    },
  },
  {
    id: 2,
    value: "Goed",
    secondaryValue: "vv = 3",
    values: {
      ep1: null,
      ep2: 1.1754,
      ep3: 1.0313,
    },
  },
  {
    id: 3,
    value: "Voldoende",
    secondaryValue: "vv = 2",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 4,
    value: "Matig",
    secondaryValue: "vv = 1,5",
    values: {
      ep1: null,
      ep2: 0.9474,
      ep3: 0.9688,
    },
  },
]
const stepItems1 = [
  {
    id: 0,
    value: "Geen WTW",
    secondaryValue: "< 2016",
    values: {
      ep1: null,
      ep2: 1.4912,
      ep3: 1.0938,
    },
  },
  {
    id: 1,
    value: "Twincoil",
    secondaryValue: "< 2016",
    values: {
      ep1: null,
      ep2: 1.2982,
      ep3: 0.875,
    },
  },
  {
    id: 2,
    value: "Wiel / Kruis",
    secondaryValue: "< 2016",
    values: {
      ep1: null,
      ep2: 1.2807,
      ep3: 0.8438,
    },
  },
  {
    id: 3,
    value: "Twincoil",
    secondaryValue: "ErP",
    values: {
      ep1: null,
      ep2: 1.0351,
      ep3: 1.0313,
    },
  },
  {
    id: 4,
    value: "Wiel / Kruis",
    secondaryValue: "ErP",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
]
const stepItems2 = [
  {
    id: 0,
    value: 80,
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 1,
    value: 65,
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 2,
    value: 55,
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 3,
    value: 50,
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 4,
    value: 45,
    values: {
      ep1: null,
      ep2: 0.9825,
      ep3: 1.0313,
    },
  },
  {
    id: 5,
    value: 40,
    values: {
      ep1: null,
      ep2: 0.9649,
      ep3: 1.0625,
    },
  },
]
const co2Buttons = [
  {
    id: 0,
    text: "Nee",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 1,
    text: "Ja",
    values: {
      ep1: null,
      ep2: 0.8772,
      ep3: 0.9375,
    },
  },
]
const bevochtigingButtons = [
  {
    id: 0,
    text: "Ja",
    values: {
      ep1: null,
      ep2: 1.0526,
      ep3: 0.9688,
    },
  },
  {
    id: 1,
    text: "Nee",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
]
const koudeopwekkingButtons = [
  {
    id: 0,
    text: "Ketel + Koelmachine",
    values: {
      ep1: null,
      ep2: 1.4211,
      ep3: 0.0,
    },
  },
  {
    id: 1,
    text: "Ketel geen koeling",
    values: {
      ep1: null,
      ep2: 1.2281,
      ep3: 0.0,
    },
  },
  {
    id: 2,
    text: "Warmtepomp lucht",
    values: {
      ep1: null,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 3,
    text: "Warmtepomp bodem",
    values: {
      ep1: null,
      ep2: 0.8246,
      ep3: 1.625,
    },
  },
  {
    id: 4,
    text: "Warmtepomp WKO",
    values: {
      ep1: null,
      ep2: 0.7368,
      ep3: 1.75,
    },
  },
  {
    id: 5,
    text: "Warmtepomp Derden",
    values: {
      ep1: null,
      ep2: 1.2632,
      ep3: 0.0,
    },
  },
]
const verlichtingButtons = [
  {
    id: 0,
    text: "TL8",
    values: {
      ep1: null,
      ep2: {
        inclDagl: 1.2632,
        exclDagl: 1.4737,
      },
      ep3: {
        inclDagl: 0.7813,
        exclDagl: 0.6563,
      },
    },
  },
  {
    id: 1,
    text: "TL5",
    values: {
      ep1: null,
      ep2: {
        inclDagl: 1.1579,
        exclDagl: 1.3158,
      },
      ep3: {
        inclDagl: 0.8438,
        exclDagl: 0.7813,
      },
    },
  },
  {
    id: 2,
    text: "LED",
    values: {
      ep1: null,
      ep2: {
        inclDagl: 1.0,
        exclDagl: 1.0877,
      },
      ep3: {
        inclDagl: 1.0,
        exclDagl: 0.9063,
      },
    },
  },
]
const numberButtons = [
  {
    id: 0,
    text: 0,
    values: {
      ep1: null,
      ep2: 0,
      ep3: 0,
    },
  },
  {
    id: 1,
    text: 5,
    values: {
      ep1: null,
      ep2: -7,
      ep3: 7,
    },
  },
  {
    id: 2,
    text: 10,
    values: {
      ep1: null,
      ep2: -14,
      ep3: 14,
    },
  },
  {
    id: 3,
    text: 20,
    values: {
      ep1: null,
      ep2: -29,
      ep3: 29,
    },
  },
  {
    id: 4,
    text: 30,
    values: {
      ep1: null,
      ep2: -43,
      ep3: 43,
    },
  },
  {
    id: 5,
    text: 40,
    values: {
      ep1: null,
      ep2: -58,
      ep3: 58,
    },
  },
]
const twoButtons = [
  {
    id: 0,
    text: "Nee",
  },
  {
    id: 1,
    text: "Ja",
  },
]

const InstallatiesSection = () => {
  const history = useHistory()

  const data = useSelector((state) => state.data)
  const dispatch = useDispatch()
  const [showOpbrengstSelector, setShowOpbrengstSelector] = useState(true)
  const [infoModalImage, setInfoModalImage] = useState(null)

  const [selectedCo2, setSelectedCo2] = useState(data?.Co2regeling?.text)
  const [selectedBevochtiging, setSelectedBevochtiging] = useState(
    data?.Bevochtiging?.text
  )
  const [selectedKoudeopwekking, setSelectedKoudeopwekking] = useState(
    data?.Koudeopwekking.text
  )
  const [selectedVerlichting, setSelectedVerlichting] = useState(
    data?.Verlichting?.text
  )
  const [selectedDaglichtregeling, setSelectedDaglichtregeling] = useState(
    data?.Daglichtregeling?.text
  )
  const [selectedNumberButtons, setSelectedNumberButtons] = useState(
    data?.NumberButtons?.text
  )

  const handleCo2Selection = (buttonContent) => {
    setSelectedCo2(buttonContent.text)
    dispatch(fillData({ ...data, Co2regeling: buttonContent }))
  }
  const handleBevochtigingSelection = (buttonContent) => {
    setSelectedBevochtiging(buttonContent.text)
    dispatch(fillData({ ...data, Bevochtiging: buttonContent }))
  }
  const handleKoudeopwekkingSelection = (value) => {
    koudeopwekkingButtons.map((button) => {
      if (button.text === value) {
        setSelectedKoudeopwekking(button.text)
        dispatch(fillData({ ...data, Koudeopwekking: button }))
      }
    })
  }
  const handleVerlichtingSelection = (buttonContent) => {
    setSelectedVerlichting(buttonContent.text)
    dispatch(fillData({ ...data, Verlichting: buttonContent }))
  }
  const handleDaglichtregelingSelection = (buttonContent) => {
    setSelectedDaglichtregeling(buttonContent.text)
    dispatch(fillData({ ...data, Daglichtregeling: buttonContent }))
  }
  const handleNumberButtons = (buttonContent) => {
    // console.log("changed: ", buttonContent)
    setSelectedNumberButtons(buttonContent.text)
    dispatch(fillData({ ...data, NumberButtons: buttonContent }))
  }

  const [oldNumberButtons, setOldNumberButtons] = useState(null)
  const [oldTotaleOpbrengst, setOldTotaleOpbrengst] = useState(0)

  const handlePvInstalatieChange = (e) => {
    // goint 2
    if (showOpbrengstSelector) {
      // save old numebr buttons
      setOldNumberButtons({ ...data.NumberButtons })
      // dispatch new values with id:6
      dispatch(
        fillData({
          ...data,
          totaleOpbrengst: oldTotaleOpbrengst,
        })
      )
    }
    // going 1
    else {
      setOldTotaleOpbrengst(data.totaleOpbrengst)
      dispatch(
        fillData({
          ...data,
          totaleOpbrengst: null,
          NumberButtons: oldNumberButtons,
        })
      )
      setSelectedNumberButtons(oldNumberButtons.text)
      // dispatch(fillData({ ...data, NumberButtons: oldNumberButtons }))
    }
    setShowOpbrengstSelector(!showOpbrengstSelector)
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalText, setInfoModalText] = useState("")
  const [infoModalTitle, setInfoModalTitle] = useState("")

  return (
    <div className="mt-8">
      <InfoModal
        open={modalOpen}
        text={infoModalText}
        title={infoModalTitle}
        image={null}
        closeModal={() => setModalOpen(false)}
      />
      <div className="flex-col">
        <HorizontalStepper
          stepItems={stepItems}
          topic="Ventilatievoud"
          setInfoModalText={setInfoModalText}
          setInfoModalTitle={setInfoModalTitle}
          setModalOpen={setModalOpen}
          text={
            <>
              <p>
                Onder de ventilatievoud wordt de uurlijkse doorspoeling van de
                gebruiksruimten met verse lucht verstaan. Deze waarde wordt
                bepaald door de hoeveelheid verse ventilatielucht (in m3/h) te
                delen door het volume van de gebruiksruimte (in m3). Hierbij
                wordt veelal een leefzone met een hoogte van 2,7 m aangehouden.
                Een lage ventilatievoud geeft weliswaar de beste
                energieprestatie, maar ook het slechtste binnenklimaat.
              </p>
              <p>
                Voorbeeld: In een ruimte met een gebruiksoppervlak van 1.000 m2
                wordt 8.100 m3/h geventileerd met 100% buitenlucht. De hoogte
                van de leefzone bedraagt 2,7 m. De ventilatievoud van de ruimte
                bedraagt hier 3,0, wat betekent dat de ruimtelucht per uur 3
                maal wordt ververst.
              </p>
              <p>
                Indien de ventilatievoud niet bekend is, dan dient uitgegaan te
                worden van minimaal 1,5-voudige ventilatie.
              </p>
            </>
          }
          setInfoModalImage={setInfoModalImage}
          image={null}
        />
      </div>
      <div className="flex flex-row">
        <HorizontalStepper
          stepItems={stepItems1}
          topic="Type LBK"
          setInfoModalText={setInfoModalText}
          setInfoModalTitle={setInfoModalTitle}
          setModalOpen={setModalOpen}
          text="Voor de energieprestatie van de luchtbehandelingskast (LBK) zijn het type warmteterugwinning en de ventilatorenergie van belang. LBK’s dienen vanaf 2016 aan de Europese ErP regelgeving te voldoen. Hierdoor is de energieprestatie aanzienlijk verbeterd, onder andere: hoger rendement ventilatoren, hoger rendement warmteterugwinning en een lagere weerstand in de kast. LBK’s die voor 2016 zijn gefabriceerd hebben veelal een lagere energieprestatie. Een warmtewiel of kruisstroomwisselaar heeft het hoogste rendement aan warmteterugwinning en dus de beste energieprestatie. Een twin-coil systeem heeft een iets lager rendement."
          setInfoModalImage={setInfoModalImage}
          image={null}
        />
      </div>
      <div className="flex flex-row w-full mt-20 place-items-center">
        <p className="text-h4 font-bold text-darkBlue font-mulish w-32">
          CO<sub>2</sub> regeling{" "}
        </p>
        <img
          src={InfoIcon}
          className="cursor-pointer h-5 ml-2 mt-2"
          alt="info"
          onClick={() => {
            setInfoModalText(
              "Het toevoeren van veel verse buitenlucht heeft een positief effect op het binnenklimaat, maar een negatief effect op de energieprestatie. Door de hoeveelheid ventilatie per verblijfsruimte af te stemmen op de actuele behoefte kan energie worden bespaard, terwijl de luchtkwaliteit goed blijft. Om dit te realiseren worden er per verblijfsruimte luchtregelaars (VAV-regelaars) en CO2-opnemers geplaatst. De ventilatoren in de LBK worden voorzien van toerenregeling."
            )
            setInfoModalTitle(
              <div>
                CO<sub>2</sub> regeling{" "}
              </div>
            )
            setModalOpen(true)
          }}
        />
        <div
          className="flex flex-wrap justify-between bg-gray h-39 ml-36"
          style={{ borderRadius: "8px" }}
        >
          {co2Buttons.map((buttonContent, i) => (
            <button
              key={buttonContent.id}
              className={`w-102 h-6 rounded-lg px-2 text-center ${
                buttonContent.text === selectedCo2 ? `font-bold` : `font-normal`
              } font-bold mt-auto mb-auto mr-2 ml-2 ${
                buttonContent.text === selectedCo2
                  ? `bg-lightBlue text-white`
                  : `bg-gray`
              }`}
              onClick={() => handleCo2Selection(buttonContent)}
            >
              {buttonContent.text !== "" && (
                <span
                  className={`block text-sm ${
                    buttonContent.text === selectedCo2
                      ? "text-white"
                      : `text-black`
                  }`}
                >
                  {buttonContent.text}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full mt-8 place-items-center">
        <p className="text-h4 font-bold text-darkBlue font-mulish w-32">
          Bevochtiging
        </p>
        <img
          src={InfoIcon}
          className="cursor-pointer h-5 ml-2 mt-2"
          alt="info"
          onClick={() => {
            setInfoModalText(
              "Het bevochtigen van een kantoorgebouw is niet altijd noodzakelijk. Personen en planten kunnen vaak zelf voldoende vocht produceren om in de winter een onaangenaam droog binnenklimaat te voorkomen. Hierdoor is een bevochtigingsinstallatie in een kantoorgebouw alleen in specifieke situaties noodzakelijk. Een bevochtigingsinstallatie gebruikt altijd energie en verslechtert daarmee de energieprestatie. In de tool wordt geen onderscheid gemaakt in het type bevochtiging."
            )
            setInfoModalTitle("Bevochtiging")
            setModalOpen(true)
          }}
        />
        <div
          className="flex flex-wrap justify-between bg-gray h-39 ml-36"
          style={{ borderRadius: "8px" }}
        >
          {bevochtigingButtons.map((buttonContent, i) => (
            <button
              key={buttonContent.id}
              className={`w-102 h-6 rounded-lg px-2 text-center ${
                buttonContent.text === selectedBevochtiging
                  ? `font-bold`
                  : `font-normal`
              } font-bold mt-auto mb-auto mr-2 ml-2 ${
                buttonContent.text === selectedBevochtiging
                  ? `bg-lightBlue text-white`
                  : `bg-gray`
              }`}
              onClick={() => handleBevochtigingSelection(buttonContent)}
            >
              {buttonContent.text !== "" && (
                <span
                  className={`block text-sm ${
                    buttonContent.text === selectedBevochtiging
                      ? "text-white"
                      : `text-black`
                  }`}
                >
                  {buttonContent.text}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full mt-20 place-items-center justify-start">
        <div className="flex gap-0 place-items-center justify-start">
          <p className="text-h4 font-bold text-darkBlue font-mulish w-48">
            Warmte- en koudeopwekking
          </p>
          <img
            src={InfoIcon}
            className="cursor-pointer h-5 ml-2 mt-2"
            alt="info"
            onClick={() => {
              setInfoModalText(
                <>
                  <p>
                    Gezien de doelstelling in het Klimaatakkoord om het
                    aardgasverbruik in Nederland af te bouwen worden nieuwe
                    gebouwen tegenwoordig voorzien van 'all-electric'
                    warmteopwekking. Een elektrische warmtepomp is hiervoor de
                    meest gangbare oplossing. Warmtepompen maken gebruik van
                    omgevingswarmte. Dit deel van de warmte wordt aangemerkt als
                    hernieuwbare energie in BENG-3. De volgende
                    warmtepompsystemen zijn opgenomen in de tool:
                  </p>
                  <ul>
                    <li className="pt-5"></li>
                    <li>
                      - Warmtepomp buitenlucht: de warmtepomp wordt buiten
                      opgesteld en gebruikt omgevingswarmte uit de buitenlucht,
                      's zomers fungeert de warmtepomp als (compressie-)
                      koelmachine;
                    </li>
                    <li>
                      - Warmtepomp bodem: de warmtepomp wordt aangesloten op een
                      gesloten bodemwarmtewisselaar en gebruikt warmte uit de
                      bodem, de opgeslagen koude wordt ’s zomers gebruikt in het
                      gebouw;
                    </li>
                    <li>
                      - Warmtepomp aquifer: de warmtepomp wordt aangesloten op
                      een open bronsysteem en gebruikt warmte uit de bodem, de
                      opgeslagen koude wordt ’s zomers gebruikt in het gebouw.
                    </li>
                  </ul>
                  <p>
                    In bestaande gebouwen zijn nog veel installaties met
                    CV-ketels en (compressie)koelmachines aanwezig. Er is dan
                    geen sprake van het gebruik van hernieuwbare energie. De
                    energieprestatie van deze systemen is aanzienlijk slechter
                    dan van een warmtepompsysteem. Een warmtepomp met aquifer
                    heeft de beste energieprestatie.
                  </p>
                </>
              )
              setInfoModalTitle("Warmte- en koudeopwekking")
              setModalOpen(true)
            }}
          />
        </div>
        <select
          className="flex flex-row justify-start bg-gray h-39 ml-20 px-5"
          style={{ borderRadius: "8px" }}
          value={selectedKoudeopwekking}
          onChange={(e) => handleKoudeopwekkingSelection(e.target.value)}
        >
          {koudeopwekkingButtons.map((buttonContent) => (
            <option className="py-2" value={buttonContent.text}>
              {buttonContent.text}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-row w-full">
        <HorizontalStepper
          stepItems={stepItems2}
          topic="Aanvoer-temperatuur CV"
          setInfoModalText={setInfoModalText}
          setInfoModalTitle={setInfoModalTitle}
          setModalOpen={setModalOpen}
          text="De Aanvoer-temperatuur CV is van invloed op de energieprestatie. Een lagere temperatuur zorgt voor minder transportverliezen en dus voor een betere energieprestatie. Daarbij zijn warmtepompsystemen gebaat bij een lage aanvoertemperatuur. Om deze reden is de maximale aanvoertemperatuur bij een warmtepompsysteem begrensd op 55 °C."
          setInfoModalImage={setInfoModalImage}
          image={null}
        />
      </div>
      <div className="flex flex-row w-full mt-20 place-items-center">
        <p className="text-h4 font-bold w-28 text-darkBlue font-mulish">
          Verlichting
        </p>
        <img
          src={InfoIcon}
          className="cursor-pointer h-5 mt-2"
          alt="info"
          onClick={() => {
            setInfoModalText(
              <>
                <p>
                  Actieve verlichting van een kantoorgebouw is nodig om een te
                  laag verlichtingsniveau op de werkplek te voorkomen. Bij veel
                  inval van daglicht is de noodzaak voor verlichting echter
                  minder, dan kan de kunstmatige verlichting worden gedimd. Door
                  gebruik te maken van een daglichtregeling kan hierbij veel
                  energie worden bespaard. De verlichting dient hierbij nog
                  steeds door de gebruiker of een aanwezigheidssensor te worden
                  ingeschakeld. De optie daglichtregeling is te selecteren in de
                  tool. De wijze van schakelen is niet te selecteren in de tool.
                </p>
                <p>
                  Het elektrisch vermogen van de verlichting is afhankelijk van
                  het type systeem. In nieuwbouwsituaties wordt tegenwoordig
                  LED-verlichting toegepast met een relatief laag vermogen. In
                  bestaande gebouwen is ook nog TL-verlichting aanwezig. Hierbij
                  wordt onderscheid gemaakt in TL5 (dunne buis) en TL8 (dikkere
                  buis).
                </p>
              </>
            )
            setInfoModalTitle("Verlichting")
            setModalOpen(true)
          }}
        />
        <div
          className="flex flex-wrap justify-between bg-gray h-39 ml-44"
          style={{ borderRadius: "8px" }}
        >
          <Tooltip />
          {verlichtingButtons.map((buttonContent, i) => (
            <button
              key={buttonContent.id}
              className={`w-16 h-6 rounded-lg px-2 text-center ${
                buttonContent.text === selectedVerlichting
                  ? `font-bold`
                  : `font-normal`
              } font-bold mt-auto mb-auto mr-2 ml-2 ${
                buttonContent.text === selectedVerlichting
                  ? `bg-lightBlue text-white`
                  : `bg-gray`
              }`}
              onClick={() => handleVerlichtingSelection(buttonContent)}
            >
              {buttonContent.text !== "" && (
                <span
                  className={`block text-sm ${
                    buttonContent.text === selectedVerlichting
                      ? "text-white"
                      : `text-black`
                  }`}
                >
                  {buttonContent.text}
                </span>
              )}
            </button>
          ))}
        </div>
        <p className="ml-8 mr-4">Daglichtregeling</p>
        <div
          className="flex flex-wrap justify-between bg-gray h-39"
          style={{ borderRadius: "8px" }}
        >
          {twoButtons.map((buttonContent, i) => (
            <button
              key={buttonContent.id}
              className={`h-6 w-16 rounded-lg px-2 text-center ${
                buttonContent.text === selectedDaglichtregeling
                  ? `font-bold`
                  : `font-normal`
              } font-bold mt-auto mb-auto mr-2 ml-2 ${
                buttonContent.text === selectedDaglichtregeling
                  ? `bg-lightBlue text-white`
                  : `bg-gray`
              }`}
              onClick={() => handleDaglichtregelingSelection(buttonContent)}
            >
              {buttonContent.text !== "" && (
                <span
                  className={`block text-sm ${
                    buttonContent.text === selectedDaglichtregeling
                      ? "text-white"
                      : `text-black`
                  }`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {buttonContent.text}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full mt-20 ">
        <div className="flex flex-row place-items-center">
          <div className="flex">
            <p className="text-h4 font-bold w-36  text-darkBlue font-mulish">
              PV - Installatie
            </p>
            <img
              src={InfoIcon}
              className="cursor-pointer h-5 mt-2"
              alt="info"
              onClick={() => {
                setInfoModalText(
                  <>
                    <p>
                      Indien het kantoorgebouw is voorzien van een
                      opwekkingsinstallatie met PV-panelen, dan dient de
                      opbrengst te worden omgerekend naar de jaarlijkse
                      opwekking per gebruiksoppervlak in kWh/m2. Hiermee kan de
                      invloed op de energieprestatie worden bepaald.
                    </p>
                    <p>
                      Voorbeeld: Een kantoorgebouw met 2.400 m2
                      gebruiksoppervlak is voorzien van 100 PV-panelen. De
                      jaarlijkse opbrengst van de PV-panelen is 255 kWh per
                      paneel. De totale opbrengst van de PV-panelen is hiermee
                      25.500 kWh. Dit komt overeen met 10,6 kWh opwekking per m2
                      gebruiksoppervlak. De opbrengst van het systeem kan worden
                      ingevoerd door direct de totale opbrengst van het systeem
                      in te vullen, of door één van de vaste keuzeopties
                      (kWh/m2) te selecteren.
                    </p>
                    <p>
                      De opwekking van elektriciteit uit zonne-energie telt
                      zowel mee in de energieprestatie van het gebouw (BENG-2)
                      als het aandeel hernieuwbare energie (BENG-3).
                    </p>
                  </>
                )
                setInfoModalTitle("PV - Installatie")
                setModalOpen(true)
              }}
            />
            <Tooltip />
          </div>
          <div className="flex flex-col ml-36">
            <div className="flex place-items-center place-content-start">
              <div className="flex items-center">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-600"
                    name="radio"
                    value="true"
                    checked={showOpbrengstSelector === true}
                    onChange={handlePvInstalatieChange}
                  />
                  <span
                    className={`font-mulish font-bold text-lightBlue ml-2 ${
                      !showOpbrengstSelector && "pointer-events-none opacity-50"
                    }`}
                  >
                    Opbrengst (kWh) per m² GO
                  </span>
                </label>
              </div>
              <div
                className={`flex justify-between w-2/5 bg-gray h-39 ml-2 ${
                  !showOpbrengstSelector && "pointer-events-none opacity-50"
                }`}
                style={{ borderRadius: "8px" }}
              >
                {numberButtons.map((buttonContent, i) => (
                  <button
                    key={buttonContent.id}
                    className={`w-102 h-6 rounded-lg px-2 text-center ${
                      buttonContent.text === selectedNumberButtons
                        ? `font-bold`
                        : `font-normal`
                    } font-bold mt-auto mb-auto mr-2 ml-2 ${
                      buttonContent.text === selectedNumberButtons
                        ? `bg-lightBlue text-white`
                        : `bg-gray`
                    }`}
                    onClick={() => handleNumberButtons(buttonContent)}
                  >
                    {buttonContent.text !== "" && (
                      <span
                        className={`block text-sm ${
                          buttonContent.text === selectedNumberButtons
                            ? "text-white"
                            : `text-black`
                        }`}
                      >
                        {buttonContent.text}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <div className="flex items-center mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-600 mt-1"
                    name="radio"
                    value="false"
                    checked={showOpbrengstSelector === false}
                    onChange={handlePvInstalatieChange}
                  />
                  <span
                    className={`ml-2 mt-1 font-mulish font-bold text-lightBlue ${
                      showOpbrengstSelector === true
                        ? "pointer-events-none opacity-50"
                        : ""
                    }`}
                  >
                    Totale opbrengst
                  </span>
                  <input
                    className={`appearance-none block w-44 bg-gray-200 ml-2 text-black bg-buttonGray text-left rounded py-3 pl-3 ml-24 leading-tight focus:outline-none focus:bg-buttonGray ${
                      showOpbrengstSelector === true
                        ? "pointer-events-none opacity-50"
                        : ""
                    }`}
                    type="number"
                    step="0.01"
                    min="0"
                    defaultValue={0}
                    onChange={(e) => {
                      setOldTotaleOpbrengst(parseInt(e.target.value))
                      dispatch(
                        fillData({
                          ...data,
                          totaleOpbrengst: parseInt(e.target.value),
                        })
                      )
                    }}
                  />
                  <span
                    className={`absolute ml-80 flex items-center text-gray-700 pointer-events-none ${
                      showOpbrengstSelector === true
                        ? "pointer-events-none opacity-50"
                        : ""
                    }`}
                  >
                    kWh
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 pb-32">
        <Button
          text={"NAAR RESULTATEN"}
          icon={ArrowRight}
          onClick={() => {
            dispatch(changeActiveRoom("Resultaten"))
            dispatch(toggleIsCalculationDone(true))
            history.push("/energielabelcheck/resultaten")
          }}
        />
        {/* <div className="mt-5">
          <Button
            text={"SHOW"}
            icon={ArrowRight}
            onClick={() => {
              console.log("totaleOpbrengst: ", data.totaleOpbrengst)
            }}
          />
        </div> */}
      </div>
    </div>
  )
}

export default InstallatiesSection

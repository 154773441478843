import React, { useEffect, useState } from "react"
import Info from "../../assets/images/info.png"
import StepItem from "./StepItem"
import { useSelector } from "react-redux"

const HorizontalStepper = ({
  stepItems,
  topic,
  setInfoModalText,
  setInfoModalTitle,
  setModalOpen,
  text = "",
  setInfoModalImage,
  image,
  wkbCheck,
  ventilatievoud,
}) => {
  const [selectedStep, setSelectedStep] = useState(0)
  const customTopic = "BENG" + topic
  const customWKBTopic = topic

  const currentID = useSelector((state) =>
    wkbCheck ? state.wkbData[customWKBTopic]?.id : state.data[customTopic]?.id
  )

  useEffect(() => {
    if (currentID) {
      setSelectedStep(currentID)
    }
  }, [currentID])

  return (
    <div
      style={{
        margin: "50px 0",
        display: "flex",
        width: "100%",
      }}
      className="font-mulish"
    >
      <div className="container flex justify-between items-center w-full min-h-[50px] place-items-center">
        <div className="flex items-center place-items-center">
          <p className="text-h4 font-bold text-darkBlue font-mulish mt-auto mb-auto">
            {topic === "Verlichting" ? "Verlichting + apparatuur" : topic}
          </p>
          {!wkbCheck && (
            <img
              className="h-5 cursor-pointer ml-2 mt-2"
              src={Info}
              alt=""
              onClick={() => {
                setInfoModalText(text)
                setInfoModalTitle(`${topic}`)
                setModalOpen(true)
                setInfoModalImage(image)
              }}
            />
          )}
        </div>
        <div
          style={{
            height: 10,
            width: `71%`,
          }}
        >
          <div
            className="flex justify-between"
            style={{
              height: 10,
              width: `100%`,
              backgroundColor: "#fafafa",
            }}
          >
            {stepItems?.map((stepItem) => (
              <StepItem
                key={stepItem.id}
                item={stepItem}
                selectedStep={selectedStep}
                setSelectedStep={setSelectedStep}
                topic={topic}
                className="z-10"
                wkbCheck={wkbCheck}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HorizontalStepper

export const verlichting = [
  {
    id: 0,
    value: "Laag",
    secondaryValue: "5 W/m²",
    values: { ventilationflow: 5 },
  },
  {
    id: 1,
    value: "Gemiddeld",
    secondaryValue: "15 W/m²",
    values: { ventilationflow: 15 },
  },
  {
    id: 2,
    value: "Hoog",
    secondaryValue: "25 W/m²",
    values: { ventilationflow: 25 },
  },
  {
    id: 3,
    value: "Zeer hoog",
    secondaryValue: "35 W/m²",
    values: { ventilationflow: 35 },
  },
]

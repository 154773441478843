/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"

import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Input from "components/Input"
import RoomBox from "components/RoomBox"
import Button from "components/Button"
import InfoModal from "components/InfoModal"
import KlimatConceptExplanation from "components/KlimatConceptExplanation"

import {
  addInstalationData,
  changeActiveRoom,
  addAirFlowHeadLoadData,
  valueTooHigh,
} from "redux/actions"
// import { powerAvailable } from 'calculationFunctions/powerAvailable'
import {
  calculcateLuchtdebiet,
  calculateInterneWarmtelastSum,
} from "calculationFunctions/calculateAirflowHeatLoad"

import instalation1 from "assets/images/instalation-1.png"
import instalation2 from "assets/images/Instalation-2.png"
import instalation3 from "assets/images/instalation-3.png"
import instalation4 from "assets/images/instalation-4.png"
import instalation5 from "assets/images/instalation-5.png"
import instalation6 from "assets/images/instalation-6.png"
import PlusIcon from "assets/images/plus-icon.svg"
import ArrowRight from "assets/images/arrow-right.svg"

import explanationOne from "assets/explanation-images/Klimaatplafonds.png"
import explanationTwo from "assets/explanation-images/Plafondinductie-units.png"
import explanationThree from "assets/explanation-images/VAV+naverwarmer.png"
import explanationFour from "assets/explanation-images/Fancoil-units.png"
import explanationFive from "assets/explanation-images/Vloerverwarming_-koeling.png"
import explanationSix from "assets/explanation-images/Radiotoren+topkoeling.png"

const InstalatieStep = ({ room, index }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const roomData = useSelector((state) => state.rooms.rooms)

  const [modalTile, setModalTile] = useState("")
  const [modalText, setModalText] = useState("")
  const [modalImage, setModalImage] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [boxes, setBoxes] = useState([
    {
      name: "Klimaatplafonds",
      image: instalation1,
      explanationImage: explanationOne,
      selected: false,
      value: 8,
    },
    {
      name: "Plafondinductie-units",
      image: instalation2,
      explanationImage: explanationTwo,
      selected: false,
      value: 2.7,
    },
    {
      name: "VAV + naverwarmer",
      image: instalation3,
      explanationImage: explanationThree,
      selected: false,
      value: 10,
    },
    {
      name: "Fancoil-units",
      image: instalation4,
      explanationImage: explanationFour,
      selected: false,
      value: 4,
    },
    {
      name: "Vloerverwarming/-koeling",
      image: instalation5,
      explanationImage: explanationFive,
      selected: false,
      value: 8,
    },
    {
      name: "Radiatoren + topkoeling",
      image: instalation6,
      explanationImage: explanationSix,
      selected: false,
      value: 8,
    },
  ])

  const [data, setData] = useState({
    instalationCapacity: [
      { name: "Ventilatiedebiet", value: "" },
      { name: "Ventilatiedebiet per persoon", value: "" },
      { name: "Ventilatievoud", value: "" },
    ],
    climateConcept: "",
  })
  const [formGood, setFormGood] = useState(true)
  const [ventilatievoud, setVentilatievoud] = useState(0)
  const [warningMessage, setWarningMessage] = useState(false)

  const changeSelected = (name) => {
    let box = [...boxes]

    boxes.forEach((elem) => {
      elem.selected = false
      if (elem.name === name) {
        elem.selected = true
      }
    })
    setBoxes([...box])
    setData({ ...data, climateConcept: name })
  }

  const addData = (whereTo) => {
    dispatch(addInstalationData(data, index))
    dispatch(changeActiveRoom(""))
    if (whereTo === "binnenklimaat-resultaten") {
      history.push("/binnenklimaat/binnenklimaat-resultaten")
    } else {
      history.push("/binnenklimaat/ruimtes")
    }
  }

  const checkForm = (whereTo) => {
    if (data.instalationCapacity[0].value === "" || data.climateConcept === "")
      return setFormGood(false)
    addData(whereTo)
  }

  const toggleModal = (title, text, image) => {
    setModalTile(title)
    setModalText(text)
    setModalImage(image)
    setModalOpen(true)
  }

  const checkIfValueIsTooHigh = (name) => {
    let value
    boxes.map((elem) => {
      if (elem.name === name) value = elem.value
    })
    if (ventilatievoud > value) {
      dispatch(valueTooHigh(index, true))
      setWarningMessage(true)
    } else {
      dispatch(valueTooHigh(index, false))
      setWarningMessage(false)
    }
  }

  const getCalculation = (name) => {
    //Once user clicked on the choice we calculate cooling and heating for given choice with given formula for that
    //and we set result to calculatedOptions state.
    checkIfValueIsTooHigh(name)
    calculateInterneWarmtelastSum()
    changeSelected(name)
  }

  useEffect(() => {
    if (!room[0]?.instalationCapacity) return

    let box = [...boxes]
    box.forEach((elem) => {
      if (elem.name === room[0].climateConcept) {
        elem.selected = true
      }
    })
    setData({ ...data, ...room[0] })
    setBoxes([...box])
  }, [])

  return (
    <div className="pt-10 pb-24">
      <InfoModal
        open={modalOpen}
        text={modalText}
        title={modalTile}
        image={modalImage}
        closeModal={() => setModalOpen(false)}
      />
      <p className="font-bold text-h3 text-darkBlue">
        Wat is de capaciteit van de installatie?
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
        <Input
          label="Ventilatiedebiet"
          info
          type="number"
          infoText="Voer de hoeveelheid verse lucht in die aan het vertrek wordt toegevoerd. Schaal het ventilatiedebiet op/af als de gekozen ‘Ruimte afmetingen’ (stap 1, Ruimtes) afwijken van de werkelijk situatie. Het ‘Ventilatiedebiet per persoon’ en de ‘Ventilatievoud’ dienen gelijk te zijn aan de werkelijke situatie."
          inputExp={
            <p className="relative">
              m<span className="absolute text-8">3</span> /uur
            </p>
          }
          onChange={(e) => {
            if (e.target.value < 0) return
            var val = { ...data }
            val.instalationCapacity[0].value = e.target.value
            setData({ ...val })

            //When user types in the data we save it to airFlowHeatLoad object and we calculate
            //the luchtdebiet because we have all data that we need for it.
            dispatch(
              addAirFlowHeadLoadData(
                index,
                "ventilatiehoeveelheid",
                e.target.value
              )
            )
            dispatch(
              addAirFlowHeadLoadData(
                index,
                "ventilatiehoeveelheidPerson",
                e.target.value / roomData[index].airFlowHeatLoadData.bezzeting
              )
            )
            calculcateLuchtdebiet()
            setVentilatievoud(
              (
                roomData[index].airFlowHeatLoadData.ventilatiehoeveelheid /
                roomData[index].airFlowHeatLoadData.totaalVloeroppervlak /
                2.7
              ).toFixed(1)
            )
          }}
          value={data?.instalationCapacity[0]?.value}
          invalid={!formGood && data?.instalationCapacity[0]?.value === ""}
        />
        <Input
          label="Ventilatiedebiet per persoon"
          info
          inputExp={
            <p className="relative">
              m<span className="absolute text-8">3</span> /uur.persoon
            </p>
          }
          value={roomData[
            index
          ].airFlowHeatLoadData.ventilatiehoeveelheidPerson.toFixed(2)}
          disabled
          infoText="Het verse luchtdebiet per persoon bepaalt de klimaatklasse voor het onderdeel luchtverversing, conform het PvE Gezonde Kantoren"
        />
        <Input
          label="Ventilatievoud"
          disabled
          info
          infoText="Het ventilatievoud geeft aan hoeveel keer per uur de gehele inhoud van de ruimte wordt vervangen voor verse lucht."
          value={(
            roomData[index].airFlowHeatLoadData.ventilatiehoeveelheid /
            roomData[index].airFlowHeatLoadData.totaalVloeroppervlak /
            2.7
          ).toFixed(1)}
        />
        {warningMessage && (
          <div className="bg-orange p-2 text-8 text-white rounded-md md:h-9 md:mt-7 flex items-center">
            Let op: ventilatievoud is ongebruikelijk hoog voor dit systeem
          </div>
        )}
      </div>
      <p className="font-bold text-h4 text-darkBlue mt-10">
        Welk klimaatconcept wordt er toegepast?
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
        {boxes.map((elem, index) => (
          <RoomBox
            title={elem.name}
            key={index}
            image={elem.image}
            selected={elem.selected}
            info
            // onClick={() => changeSelected(elem.name)}
            onInfoClick={() =>
              toggleModal(
                elem.name,
                <KlimatConceptExplanation concept={elem.name} />,
                elem.explanationImage
              )
            }
            onClick={() => getCalculation(elem.name)}
          />
        ))}
      </div>
      <div className="mt-8">
        <Button
          isDark
          text="VOEG EEN RUIMTE TOE"
          icon={PlusIcon}
          onClick={() => checkForm("rooms")}
        />
      </div>
      <div className="mt-5">
        <Button
          text="NAAR RESULTATEN"
          isDark={false}
          icon={ArrowRight}
          onClick={() => checkForm("binnenklimaat-resultaten")}
        />
      </div>
    </div>
  )
}

export default InstalatieStep

import React, { useState, useCallback, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fillWkbData } from "redux/actions"
import InfoModal from "components/InfoModal"
import HorizontalStepper from "components/BENGGebouwSection/HorizontalStepper"
import ArrowRight from "assets/images/arrow-right.svg"
import RoomBox from "components/RoomBox"
import KlimatConceptExplanation from "components/KlimatConceptExplanation"
import MediumHeadline from "components/MediumHeadline"
import ThermalBalanceTables from "components/Tables/ThermalBalanceTables"
import { ventilation as initialVentilation, climateBoxes } from "./data"
import { calculateVentilatievoud } from "../../../calculationFunctions/wkbCalculations/installatiesCalculations"
import WarningMessage from "components/WarningMessage"
import {
  getFancoilUnitsValues,
  getKlimaatplafondsValues,
  getPlafondInductieValues,
  getVAVValues,
  getVloerverwarmingValues,
  getRadiatorenValues,
} from "calculationFunctions/wkbCalculations/climateConceptCalc"
import Button from "components/Button"

const WKBInstallatiesSection = ({ onChangeStep }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.wkbData)

  const [ventilation, setVentilation] = useState(initialVentilation)
  const [boxes, setBoxes] = useState(climateBoxes)
  const [infoModal, setInfoModal] = useState({
    image: null,
    open: false,
    text: "",
    title: "",
  })

  useEffect(() => {
    if (data.climateBoxes) {
      setBoxes((prevBoxes) =>
        prevBoxes.map((box) => ({
          ...box,
          selected: box.name === data.climateBoxes.name,
        }))
      )
    }
  }, [data.climateBoxes])

  const warnings = useMemo(() => {
    const box = data?.climateBoxes
    console.log(data)
    console.log(
      box?.maxVentilationRate,
      Number(data?.Ventilatievoud.secondaryValue.replace(",", ".")) > 6,
      data?.Ventilatievoud.secondaryValue < 8
    )
    return {
      warningMessage1:
        data?.climateBoxes?.name === "Plafondinductie-units" &&
        Number(data?.Ventilatievoud.secondaryValue.replace(",", ".")) >= 6 &&
        Number(data?.Ventilatievoud.secondaryValue.replace(",", ".")) <= 8,
      warningMessage2: box?.lowControlSpeed === 1,
      warningMessage3: box?.lowControlAccuracy === 1,
      warningMessage4: box?.noCoolingAdjustment === 1,
    }
  }, [data])

  const changeSelected = useCallback((name) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) => ({
        ...box,
        selected: box.name === name,
      }))
    )
  }, [])
  const getCalculation = useCallback(
    (name) => {
      changeSelected(name)

      let selectedBoxValues
      switch (name) {
        case "Klimaatplafonds":
          selectedBoxValues = getKlimaatplafondsValues(
            data.floorArea,
            data.Ventilatievoud?.values.vent
          )
          break
        case "Plafondinductie-units":
          selectedBoxValues = getPlafondInductieValues(
            data.floorArea,
            data.Ventilatievoud?.values.vent
          )
          break
        case "VAV + naverwarmer":
          selectedBoxValues = getVAVValues(
            data.floorArea,
            data.Ventilatievoud?.values.vent
          )
          break
        case "Fancoil-units":
          selectedBoxValues = getFancoilUnitsValues(
            data.floorArea,
            data.Ventilatievoud?.values.vent,
            data.floorHeight
          )
          break
        case "Vloerverwarming/-koeling":
          selectedBoxValues = getVloerverwarmingValues(
            data.floorArea,
            data.Ventilatievoud?.values.vent
          )
          break
        case "Radiatoren + topkoeling":
          selectedBoxValues = getRadiatorenValues(
            data.floorArea,
            data.Ventilatievoud?.values.vent
          )
          break
        default:
          selectedBoxValues = null
      }

      const selectedBox = boxes.find((box) => box.name === name)

      dispatch(
        fillWkbData({
          ...data,
          climateBoxes: {
            ...selectedBox,
            selected: true,
            values: selectedBoxValues,
          },
        })
      )
    },
    [boxes, changeSelected, data, dispatch]
  )

  const toggleModal = useCallback((name, component, image) => {
    setInfoModal({
      title: name,
      text: component,
      image,
      open: true,
    })
  }, [])

  useEffect(() => {
    const updatedVentilation = initialVentilation.map((ventItem) => ({
      ...ventItem,
      values: {
        ...ventItem.values,
        vent: calculateVentilatievoud(
          data.floorArea,
          data.floorHeight,
          ventItem.values.v
        ),
      },
    }))

    setVentilation(updatedVentilation)

    dispatch(
      fillWkbData({
        ...data,
        ventilation: updatedVentilation,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.floorArea, data.floorHeight, dispatch])

  useEffect(() => {
    if (data?.Ventilatievoud?.values?.vent) {
      boxes.forEach((box) => {
        if (box.selected) {
          getCalculation(box.name)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.Ventilatievoud?.values?.vent])

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
      <div>
        <MediumHeadline
          text="Installaties"
          subtext="In dit blad kies je lokale klimaatinstallatie van het kantoorvertrek. Op basis van
de ventilatiecapaciteit kan de ventilatievoud (de doorspoeling per uur) worden
bepaald en ingevoerd. Vervolgens kan het type lokale klimaatinstallatie worden
gekozen. Per klimaatconcept is aanvullende informatie opgenomen in de tool
(zie ‘i’). De maximale afgiftevermogens voor lokale verwarming en lokale koeling
worden automatische ingevuld in de warmtebalans."
          shortText="Kies hier de lokale klimaatinstallatie voor jouw kantoorvertrek. Met de klimaatinstallatie wordt de resulterende warmte- en koudebalans per comfortklasse bepaald. "
          info
        />
        <div>
          <InfoModal
            open={infoModal.open}
            text={infoModal.text}
            title={infoModal.title}
            image={infoModal.image}
            closeModal={() =>
              setInfoModal((prev) => ({ ...prev, open: false }))
            }
          />
        </div>

        <div>
          {warnings.warningMessage1 && (
            <WarningMessage title="Ventilatievoud is ongebruikelijk hoog voor dit systeem" />
          )}
          {warnings.warningMessage2 && (
            <WarningMessage title="Dit concept kent een lage regelsnelheid" />
          )}
          {warnings.warningMessage3 && (
            <WarningMessage title="Dit concept kent een lage regelnauwkeurigheid" />
          )}
          {warnings.warningMessage4 && (
            <WarningMessage title="Dit concept kent geen naregeling koeling" />
          )}

          <div className="flex flex-row w-full ">
            <HorizontalStepper
              stepItems={ventilation}
              topic="Ventilatievoud"
              wkbCheck
              ventilatievoud
            />
          </div>
        </div>

        <div className="my-10">
          <p className="font-bold text-h4 text-darkBlue mt-10">
            Welk klimaatconcept wordt er toegepast?
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
            {boxes.map((elem, index) => (
              <RoomBox
                title={elem.name}
                key={index}
                image={elem.image}
                selected={elem.selected}
                info
                onInfoClick={() =>
                  toggleModal(
                    elem.name,
                    <KlimatConceptExplanation concept={elem.name} />,
                    elem.explanationImage
                  )
                }
                onClick={() => getCalculation(elem.name)}
              />
            ))}
          </div>
          <div className="mt-24 pb-32">
            <Button
              btnStyling="w-full flex items-center justify-center py-3 px-2 cursor-pointer my-16"
              text={"NAAR RESULTAAT"}
              icon={ArrowRight}
              onClick={() => {
                onChangeStep(6)
                document
                  .getElementById("flowBodySection")
                  ?.scrollIntoView({ behavior: "smooth" })
              }}
            />
          </div>
        </div>
      </div>
      <ThermalBalanceTables />
    </div>
  )
}

export default WKBInstallatiesSection

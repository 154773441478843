import Space2 from "assets/images/space-2.svg"
import NordWest from "assets/images/nord-west.svg"
import instalation2 from "assets/images/Instalation-2.png"
import explanationTwo from "assets/explanation-images/Plafondinductie-units.png"

const INITAL_STATE = {
  Naam: "",
  Email: "",
  width: 3.6, // breedte
  depth: 5.4, // diepte
  floorHeight: 3.0, // Verdiepingshoogte
  ceilingHeight: 2.7, // plafondhoogte
  floorArea: 17.7, // (width * depth)
  persons: 2, // occupancy
  space: {
    name: "Tussenvertrek",
    selected: true,
    image: Space2,
    wall1: 1,
    wall2: 0,
    disabled: false,
  },
  orientation: {
    name: "Zuid-West",
    selected: true,
    image: NordWest,
    rotate: 270,
    cool: 469,
    reductieHoekvertrek: 0.24,
    redfactorblinds: 0.39,
    reductionA: 0.074,
    reductionB: 0.148,
    reductionC: 0.222,
    disabled: false,
  },

  // GEVEL
  Glaspercentage: {
    id: 2,
    value: "50%",
    values: { percentage: 0.5 },
  },
  Glas: {
    id: 3,
    value: "HR++",
    secondaryValue: "U = 1,6",
    values: {
      transglasRel: 36.8,
    },
  },
  Isolatie: {
    id: 4,
    value: "Nieuwbouw",
    secondaryValue: "Rc = 4,7",
    values: { transwallRel: 6.75 },
  },
  Luchtdichtheid: {
    id: 4,
    value: "Goed",
    secondaryValue: "0,2 tot 0,4",
    values: {
      inf3_6: 0.00039,
      inf6_12: 0.0005,
      inf12_20: 0.00063,
      inf20_30: 0.00077,
      inf_30: 0.00092,
    },
  },
  Gebouwhoogte: {
    id: 2,
    value: "12 - 20 m",
    values: {
      zeerSlecht: 0.00189,
      slecht: 0.00175,
      matig: 0.0014,
      gemiddeld: 0.00103,
      goed: 0.00063,
      uitstekend: 0.00043,
    },
  },
  GlasZonwerend: { id: 0, text: "Nee", values: { zta: 0.55 } },
  Zonwering: { id: 0, text: "Ja", values: { zta: 0.55 } },

  //GEBRUIK
  Verlichting: {
    id: 1,
    value: "Gemiddeld",
    secondaryValue: "15 W/m²",
    values: { ventilationflow: 15 },
  },

  //INSTALLATIES
  Ventilatievoud: {
    id: 2,
    secondaryValue: "2,0",
    values: {
      v: 2.0,
    },
  },

  climateBoxes: {
    name: "Plafondinductie-units",
    image: instalation2,
    explanationImage: explanationTwo,
    selected: true,
    maxVentilationRate: 4.5,
    lowControlSpeed: 0,
    lowControlAccuracy: 0,
    noCoolingAdjustment: 0,
  },
}

export default function wkbDetails(state = INITAL_STATE, action) {
  switch (action.type) {
    case "FILL_WKB_DATA": {
      return { ...state, ...action.payload }
    }
    case "RESET_WKB_DATA": {
      return { ...INITAL_STATE }
    }
    default: {
      return state
    }
  }
}

import instalation1 from "assets/images/instalation-1.png"
import instalation2 from "assets/images/Instalation-2.png"
import instalation3 from "assets/images/instalation-3.png"
import instalation4 from "assets/images/instalation-4.png"
import instalation5 from "assets/images/instalation-5.png"
import instalation6 from "assets/images/instalation-6.png"

import explanationOne from "assets/explanation-images/Klimaatplafonds.png"
import explanationTwo from "assets/explanation-images/Plafondinductie-units.png"
import explanationThree from "assets/explanation-images/VAV+naverwarmer.png"
import explanationFour from "assets/explanation-images/Fancoil-units.png"
import explanationFive from "assets/explanation-images/Vloerverwarming_-koeling.png"
import explanationSix from "assets/explanation-images/Radiotoren+topkoeling.png"

export const ventilation = [
  {
    id: 0,
    secondaryValue: "1,0",
    values: {
      v: 1.0,
    },
  },
  {
    id: 1,
    secondaryValue: "1,5",
    values: {
      v: 1.5,
    },
  },
  {
    id: 2,
    secondaryValue: "2,0",
    values: {
      v: 2.0,
    },
  },
  {
    id: 3,
    secondaryValue: "3,0",
    values: {
      v: 3.0,
    },
  },
  {
    id: 4,
    secondaryValue: "4,0",
    values: {
      v: 4.0,
    },
  },
  {
    id: 5,
    secondaryValue: "6,0",
    values: {
      v: 6.0,
    },
  },
  {
    id: 6,
    secondaryValue: "8,0",
    values: {
      v: 8.0,
    },
  },
]

export const climateBoxes = [
  {
    name: "Klimaatplafonds",
    image: instalation1,
    explanationImage: explanationOne,
    maxVentilationRate: 4.5, // max. ventilatievoud
    lowControlSpeed: 0, //lage regelsnelheid
    lowControlAccuracy: 0, //lage regelnauwkeurigheid
    noCoolingAdjustment: 0, //geen naregeling koeling
    selected: false,
    values: {},
  },
  {
    name: "Plafondinductie-units",
    image: instalation2,
    explanationImage: explanationTwo,
    selected: false,
    maxVentilationRate: 4.5,
    lowControlSpeed: 0,
    lowControlAccuracy: 0,
    noCoolingAdjustment: 0,
    values: {},
  },
  {
    name: "VAV + naverwarmer",
    image: instalation3,
    explanationImage: explanationThree,
    maxVentilationRate: 8.5,
    lowControlSpeed: 0,
    lowControlAccuracy: 0,
    noCoolingAdjustment: 0,
    selected: false,
    values: {},
  },
  {
    name: "Fancoil-units",
    image: instalation4,
    explanationImage: explanationFour,
    maxVentilationRate: 4.5,
    lowControlSpeed: 0,
    lowControlAccuracy: 0,
    noCoolingAdjustment: 0,
    selected: false,
    values: {},
  },
  {
    name: "Vloerverwarming/-koeling",
    image: instalation5,
    explanationImage: explanationFive,
    maxVentilationRate: 8.5,
    lowControlSpeed: 1,
    lowControlAccuracy: 0,
    noCoolingAdjustment: 0,
    selected: false,
    values: {},
  },
  {
    name: "Radiatoren + topkoeling",
    image: instalation6,
    explanationImage: explanationSix,
    maxVentilationRate: 8.5,
    lowControlSpeed: 0,
    lowControlAccuracy: 1,
    noCoolingAdjustment: 1,
    selected: false,
    values: {},
  },
]

import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Input from "components/Input"
import Button from "components/Button"
import ArrowRight from "assets/images/arrow-right.svg"
import { fillData, fillWkbData } from "redux/actions"
import Select from "components/Select"
import MediumHeadline from "components/MediumHeadline"
import { firebaseAuth } from "firebase-config"
import ProjectService from "services/project.service"
import { addProjectToFirebase } from "redux/actions"
import ErrorAlert from "components/ErrorAlert"

const options = [{ value: "option1", label: "Kantoor" }]

const AlgemeenSection = ({
  email,
  setEmail,
  gebruiksoppervlakte,
  setGebruiksoppervlakte,
  onChangeStep,
  wkbcheck,
  buttonText = "NAAR GEBOUW",
}) => {
  const data = useSelector((state) => (wkbcheck ? state?.wkbData : state.data))
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [customErrorText, setCustomErrorText] = useState(null)
  const [isError, setIsError] = useState(false)

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    dispatch(fillData({ ...data, Kantoor: selectedOption.label }))
  }

  const setEmailError = () => {
    setEmail(false)
    setGebruiksoppervlakte(false)
    return false
  }

  const checkForEmail = () => {
    let regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    console.log(data)
    if (data.Email === "" || !regEmail.test(data.Email)) {
      setCustomErrorText("Deze informatie is verplicht.")
      return setEmailError()
    } else if (
      data.Gebruiksoppervlakte === "" ||
      parseInt(data.Gebruiksoppervlakte) < 500
    ) {
      setCustomErrorText("Het minimale gebruiksoppervlakte bedraagt 500 m2")
      return setEmailError()
    } else {
      return addProject()
    }
  }

  const addProject = async () => {
    try {
      if (data.projectAddedToFirebase) return true
      firebaseAuth
        .signInAnonymously()
        .then(() => {
          const response = new ProjectService()
            .create({
              createdAt: new Date(),
              email: data.Email,
              createdPDF: false,
              name: "WKBcheck",
              toolId: 4,
            })
            .then((res) => {
              dispatch(addProjectToFirebase(res.id))
            })
            .catch((err) => {
              console.error(err)
            })
        })
        .catch((err) => {
          console.error(err)
        })
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  return (
    <div className="mt-8">
      {!wkbcheck && (
        <>
          <p className="text-h4 font-bold text-darkBlue pb-3">Gebouw</p>
          <div className="flex flex-col md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5">
            <Input
              placeholder="Gebouwnaam"
              label={"Gebouwnaam"}
              type="text"
              value={data.Gebouwnaam}
              onChange={(e) =>
                dispatch(fillData({ ...data, Gebouwnaam: e.target.value }))
              }
            />
            <Select
              label={"Gebouwfunctie"}
              placeholder={"Kantoor"}
              options={options}
              onChange={handleSelectChange}
              value={selectedOption}
            />
            <Input
              placeholder="Versienummer"
              label={"Versienummer"}
              type="text"
              value={data.Versienummer}
              onChange={(e) =>
                dispatch(fillData({ ...data, Versienummer: e.target.value }))
              }
            />
            <Input
              placeholder="Gebruiksoppervlakte"
              label={"Gebruiksoppervlakte (m²)*"}
              type="number"
              value={data.Gebruiksoppervlakte}
              invalid={!gebruiksoppervlakte}
              onChange={(e) => {
                dispatch(
                  fillData({ ...data, Gebruiksoppervlakte: e.target.value })
                )
                setGebruiksoppervlakte(true)
              }}
            />
          </div>
        </>
      )}

      {!wkbcheck && (
        <p className="text-h4 font-bold text-darkBlue pb-3">Opgesteld door</p>
      )}

      {wkbcheck && (
        <div
          className={`flex flex-col md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5`}
        >
          <MediumHeadline
            text="Algemeen"
            subtext="Met de WKB-check kan snel een inschatting worden gemaakt van de warmte- en
koudebalans van een kantoorvertrek. In deze tool wordt o.b.v. 12 belangrijke
kenmerken een inschatting van het warmteverlies in de wintersituatie en de
koellast in de zomersituatie gemaakt. Vervolgens wordt o.b.v. de gekozen
klimaatinstallatie een inschatting gemaakt van het zomer- en wintercomfort
volgens het PvE Gezonde Kantoren 2021."
            shortText="Vul hieronder jouw gegevens in om te kunnen starten met de WKB check"
            info
          />
        </div>
      )}
      <ErrorAlert
        open={isError}
        setIsError={setIsError}
        errorText={customErrorText}
      />
      <div
        className={`flex flex-col md:grid ${
          wkbcheck ? "md:w-1/2 " : ""
        } md:grid-cols-2 md:gap-x-10 md:gap-y-5 mt-1 mb-5`}
      >
        <Input
          placeholder="Naam"
          label={"Naam"}
          type="text"
          value={data.Naam}
          onChange={(e) =>
            wkbcheck
              ? dispatch(fillWkbData({ ...data, Naam: e.target.value }))
              : dispatch(fillData({ ...data, Naam: e.target.value }))
          }
        />

        {!wkbcheck && (
          <Input
            placeholder="Bedrijf"
            label={"Bedrijf"}
            type="text"
            value={data.Bedrijf}
            onChange={(e) =>
              dispatch(fillData({ ...data, Bedrijf: e.target.value }))
            }
          />
        )}

        <Input
          placeholder="E-mail"
          label={"E-mail *"}
          type="email"
          value={data.Email}
          disabled={data.projectAddedToFirebase}
          invalid={!email}
          onChange={(e) => {
            wkbcheck
              ? dispatch(fillWkbData({ ...data, Email: e.target.value }))
              : dispatch(fillData({ ...data, Email: e.target.value }))
            setEmail(true)
          }}
        />

        {!wkbcheck && (
          <Input
            placeholder="Telefoonnummer"
            label={"Telefoonnummer"}
            type="tel"
            value={data.Telefoonnummer}
            onChange={(e) =>
              dispatch(fillData({ ...data, Telefoonnummer: e.target.value }))
            }
          />
        )}
      </div>

      <div
        className={`mt-24 pb-32  ${wkbcheck ? "md:w-1/2 " : ""}
        `}
      >
        <Button
          text={buttonText}
          icon={ArrowRight}
          onClick={async () => {
            console.log("buttonText", buttonText)
            if (buttonText === "NAAR GEOMETRIE") {
              console.log("HELLOO")
              await checkForEmail()
            }
            onChangeStep(2)
            document
              .getElementById("flowBodySection")
              ?.scrollIntoView({ behavior: "smooth" })
          }}
        />
      </div>
    </div>
  )
}

export default AlgemeenSection

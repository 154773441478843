import React, { useEffect, useState, useMemo, useCallback } from "react"
import { fillWkbData } from "redux/actions"
import { useDispatch, useSelector } from "react-redux"

import InfoModal from "components/InfoModal"
import Button from "components/Button"
import GeometrieInput from "components/GeometrieInput"
import ErrorAlert from "components/ErrorAlert"
import RoomBox from "components/RoomBox"
import MediumHeadline from "components/MediumHeadline"
import ThermalBalanceTables from "components/Tables/ThermalBalanceTables"
import { orientations, spaces } from "./data"
import { calculateVentilatievoud } from "../../../calculationFunctions/wkbCalculations/installatiesCalculations"

import ArrowRight from "assets/images/arrow-right.svg"
import InfoIcon from "assets/images/info.png"
import GebouwImage from "assets/images/Compactheid-popup-image.png"
import { getPlafondInductieValues } from "calculationFunctions/wkbCalculations/climateConceptCalc"

const GeometrySection = ({ onChangeStep }) => {
  const dispatch = useDispatch()
  const wkbData = useSelector((state) => state.wkbData)
  const ventilatievoud = useSelector((state) => state.wkbData.Ventilatievoud)

  const [modalState, setModalState] = useState({
    open: false,
    text: "",
    title: "",
    image: null,
  })
  const [isError, setIsError] = useState(false)
  const [customErrorText, setCustomErrorText] = useState(null)
  const [options, setOptions] = useState({
    space: spaces,
    orientation: orientations,
  })
  const [errors, setErrors] = useState({
    width: false,
    depth: false,
    floorHeight: false,
    ceilingHeight: false,
  })

  const infoModalContent = useMemo(
    () => ({
      text: "De compactheid betreft de verhouding tussen het verliesoppervlak (Als) en het gebruiksoppervlak (Ag) van het gebouw. Een lage compactheid geeft de beste energieprestatie, er is dan relatief weinig verliesoppervlak. In onderstaande afbeeldingen zijn een aantal voorbeelden van de compactheid gegeven. U bepaalt de compactheid door het totale oppervlak van de thermische schil (gevels, daken en vloer) te delen door het totale gebruiksoppervlakte van het gebouw.",
      title: "Compactheid",
      image: GebouwImage,
    }),
    []
  )

  const validateInputs = () => {
    const requiredFields = ["width", "depth", "floorHeight", "ceilingHeight"]

    const newErrors = requiredFields.reduce((acc, field) => {
      // Check if any required fields are missing or invalid
      acc[field] =
        !wkbData[field] ||
        (field === "ceilingHeight" && wkbData.ceilingHeight > 4) ||
        (field === "ceilingHeight" &&
          wkbData.ceilingHeight > wkbData.floorHeight)
      return acc
    }, {})

    setErrors(newErrors)

    // Check if all values are valid
    return (
      Object.values(newErrors).every((error) => !error) &&
      wkbData.ceilingHeight <= 4 &&
      wkbData.ceilingHeight <= wkbData.floorHeight
    )
  }

  const handleNextStep = () => {
    if (validateInputs()) {
      onChangeStep(3)
      document
        .getElementById("flowBodySection")
        ?.scrollIntoView({ behavior: "smooth" })
    } else {
      let errorMessage = "Please fill in all required fields."

      if (wkbData?.ceilingHeight > 4) {
        errorMessage = "De plafondhoogte mag niet hoger zijn dan 4 meter."
        setIsError(true)
      } else if (wkbData?.ceilingHeight > wkbData?.floorHeight) {
        errorMessage =
          "Plafondhoogte mag niet hoger zijn dan Verdiepingshoogte."
        setIsError(true)
      }
      setCustomErrorText(errorMessage)
      alert(errorMessage)
    }
  }

  const getSelectedSpace = (selectedSpaceName) => {
    return spaces.find((space) => space.name === selectedSpaceName)
  }
  const getSelectedOrientation = (selectedOrientationName) => {
    return orientations.find(
      (orientation) => orientation.name === selectedOrientationName
    )
  }

  // Function to deselect all items in a given category (space or orientation)
  const deSelectAll = useCallback(
    (key) => {
      setOptions((prevOptions) => ({
        ...prevOptions,
        [key]: prevOptions[key]?.map((item) => ({ ...item, selected: false })),
      }))

      if (key === "space") {
        dispatch(
          fillWkbData({
            space: "",
          })
        )
      } else if (key === "orientation") {
        dispatch(
          fillWkbData({
            orientation: "",
          })
        )
      }
    },
    [dispatch]
  )

  //Function to select an item in a given category (space or orientation)
  const selectItem = useCallback(
    (key, elem) => {
      setOptions((prevOptions) => ({
        ...prevOptions,
        [key]: prevOptions[key]?.map((item) => ({
          ...item,
          selected: item.name === elem.name,
        })),
      }))

      // Find the entire object and dispatch it to the reducer
      if (key === "space") {
        const selectedSpace = getSelectedSpace(elem.name)
        dispatch(
          fillWkbData({
            space: selectedSpace,
          })
        )
      } else if (key === "orientation") {
        const selectedOrientation = getSelectedOrientation(elem.name)
        dispatch(
          fillWkbData({
            orientation: selectedOrientation,
            Zonwering: {
              ...wkbData.Zonwering,
              values: {
                ...wkbData.Zonwering.values,
                zta: wkbData.Zonwering.text === "Ja" ? 0.3 : 0.55,
              },
            },
          })
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  // Recalculate floor area when width or depth changes
  useEffect(() => {
    if (wkbData?.width && wkbData?.depth) {
      const newFloorArea = parseFloat(
        (wkbData.width - 0.2) * (wkbData.depth - 0.2)
      )
      if (newFloorArea !== wkbData?.floorArea) {
        dispatch(
          fillWkbData({
            floorArea: newFloorArea,
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wkbData?.width, wkbData?.depth, dispatch])

  // Calculate Ventilatievoud when floor area, floor height, or factor changes
  useEffect(() => {
    const factor = ventilatievoud?.values?.v
    if (wkbData?.floorArea && wkbData?.floorHeight && factor) {
      const vent = calculateVentilatievoud(
        wkbData?.floorArea,
        wkbData?.floorHeight,
        factor
      )
      dispatch(
        fillWkbData({
          Ventilatievoud: {
            ...ventilatievoud,
            values: {
              ...ventilatievoud.values,
              vent,
            },
          },
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wkbData?.floorArea, wkbData?.floorHeight, dispatch])

  // Calculate climateBoxes when floor area, floor height, or factor changes
  useEffect(() => {
    const factor = ventilatievoud?.values?.v

    if (wkbData?.floorArea && wkbData?.floorHeight && factor) {
      const { cooling, heating } = getPlafondInductieValues(
        wkbData.floorArea,
        wkbData.Ventilatievoud?.values.vent
      )

      dispatch(
        fillWkbData({
          climateBoxes: {
            ...wkbData?.climateBoxes,
            values: {
              cooling,
              heating,
            },
          },
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wkbData?.floorArea,
    wkbData?.floorHeight,
    wkbData?.Ventilatievoud,
    dispatch,
  ])

  useEffect(() => {
    const updateSelectedOptions = () => {
      const selectedSpace = wkbData.space
      const selectedOrientation = wkbData.orientation

      setOptions((prevOptions) => ({
        space: prevOptions.space.map((item) => ({
          ...item,
          selected: item.name === selectedSpace?.name,
        })),
        orientation: prevOptions.orientation.map((item) => ({
          ...item,
          selected: item.name === selectedOrientation?.name,
        })),
      }))
    }

    updateSelectedOptions()
  }, [wkbData.space, wkbData.orientation])

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
      <div>
        <InfoModal
          open={modalState.open}
          text={modalState.text}
          title={modalState.title}
          image={modalState.image}
          closeModal={() => setModalState({ open: false })}
        />
        <ErrorAlert
          open={isError}
          setIsError={setIsError}
          errorText={customErrorText}
        />
        <MediumHeadline
          text="Geometrie"
          subtext="In dit blad voer je de geometrie van het kantoorvertrek in, als een rechthoekige
vorm. Hierbij kan worden gekozen uit een hoekvertrek, een tussenvertrek of een
inpandig vertrek. Tot slot wordt de oriëntatie van de gevel gekozen. Bij een
hoekvertrek kies je hier de gemiddelde oriëntatie van de 2 geveldelen."
          shortText="Pas hieronder de belangrijkste kenmerken van de geometrie van de ruimte aan naar de situatie die je wilt doorrekenen. Hiermee zetten we de basis van de berekening."
          info
        />
        <div className="flex items-center place-items-center">
          <p className="text-h4 font-bold text-darkBlue mt-12">
            Wat voor afmetingen heeft de ruimte?
          </p>
        </div>

        <div className="mt-6">
          <div className="grid grid-cols-3 gap-4 mb-4">
            <GeometrieInput
              id="width"
              label="Breedte*"
              unit="m"
              value={wkbData?.width || ""}
              onChange={(e) =>
                dispatch(
                  fillWkbData({ width: parseFloat(e.target.value) || "" })
                )
              }
              error={errors.width ? "Dit veld is verplicht" : ""}
            />
            <GeometrieInput
              id="depth"
              label="Diepte*"
              unit="m"
              value={wkbData?.depth || ""}
              onChange={(e) =>
                dispatch(
                  fillWkbData({ depth: parseFloat(e.target.value) || "" })
                )
              }
              error={errors.depth ? "Dit veld is verplicht" : ""}
            />
            <GeometrieInput
              id="floorArea"
              label="Vloeroppervlakte*"
              unit="m²"
              value={
                typeof wkbData?.floorArea === "number"
                  ? wkbData.floorArea.toFixed(1)
                  : ""
              }
              readOnly
            />
            <GeometrieInput
              id="floorHeight"
              label="Verdiepingshoogte*"
              unit="m"
              value={wkbData?.floorHeight || ""}
              onChange={(e) =>
                dispatch(
                  fillWkbData({
                    floorHeight: parseFloat(e.target.value) || "",
                  })
                )
              }
              error={errors.floorHeight ? "Dit veld is verplicht" : ""}
            />
            <GeometrieInput
              id="ceilingHeight"
              label="Plafondhoogte*"
              unit="m"
              value={wkbData?.ceilingHeight || ""}
              onChange={(e) => {
                const inputValue = parseFloat(e.target.value) || ""
                dispatch(fillWkbData({ ceilingHeight: inputValue }))
              }}
              error={
                errors.ceilingHeight
                  ? "Dit veld is verplicht"
                  : wkbData?.ceilingHeight > 4
                  ? "De plafondhoogte mag niet hoger zijn dan 4 meter"
                  : ""
              }
            />
          </div>
        </div>
        <SpaceSelection
          options={options.space}
          deSelectAll={deSelectAll}
          selectItem={selectItem}
        />
        <OrientationSelection
          options={options.orientation}
          deSelectAll={deSelectAll}
          selectItem={selectItem}
        />
        <Button
          btnStyling="w-full flex items-center justify-center py-3 px-2 cursor-pointer my-16"
          text="NAAR GEVEL"
          icon={ArrowRight}
          onClick={handleNextStep}
        />
      </div>

      <ThermalBalanceTables />
    </div>
  )
}

const SpaceSelection = ({ options, deSelectAll, selectItem }) => (
  <div className="mt-12">
    <p className="text-h4 font-bold text-darkBlue">
      Wat voor afmetingen heeft de ruimte?
    </p>
    <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-5 mt-5">
      {options?.map((elem, i) => (
        <RoomBox
          title={elem.name}
          key={i}
          selected={elem.selected}
          image={elem.image}
          disabled={elem.disabled}
          onClick={() => {
            if (!elem.disabled) {
              deSelectAll("space")
              selectItem("space", elem)
            }
          }}
        />
      ))}
    </div>
  </div>
)

const OrientationSelection = ({ options, deSelectAll, selectItem }) => (
  <div className="mt-12">
    <p className="text-h4 font-bold text-darkBlue">
      Wat is de oriëntatie van de gevel?
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {options?.map((elem, i) => (
        <RoomBox
          title={elem.name}
          key={i}
          selected={elem.selected}
          image={elem.image}
          disabled={elem.disabled}
          rotate={elem?.rotate}
          onClick={() => {
            if (!elem.disabled) {
              deSelectAll("orientation")
              selectItem("orientation", elem)
            }
          }}
        />
      ))}
    </div>
  </div>
)

export default GeometrySection

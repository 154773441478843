import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fillWkbData } from "redux/actions"

import Button from "components/Button"
import ArrowRight from "assets/images/arrow-right.svg"
import HorizontalStepper from "components/BENGGebouwSection/HorizontalStepper"
import ThermalBalanceTables from "components/Tables/ThermalBalanceTables"
import MediumHeadline from "components/MediumHeadline"
import {
  glaspercentage,
  glas,
  isolatie,
  luchtdichtheid,
  gebouwhoogte,
  zonwering,
  glasZonwerend,
} from "./data"

const GevelSection = ({ onChangeStep }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.wkbData)

  const commonButtonClasses =
    "w-102 h-6 rounded-lg px-2 text-center font-bold mt-auto mb-auto mr-2 ml-2"
  const selectedButtonClasses = "bg-lightBlue text-white"
  const unselectedButtonClasses = "bg-gray"

  const handleSelection = (type, selectedValue) => {
    dispatch(
      fillWkbData({
        ...data,
        [type]: selectedValue,
      })
    )
  }

  const renderButtonGroup = (items, selectedValue, type) => (
    <div className="flex flex-wrap justify-between bg-gray h-39 ml-36 rounded-lg">
      {items.map((buttonContent) => (
        <button
          key={buttonContent.id}
          className={`${commonButtonClasses} ${
            buttonContent.text === selectedValue.text
              ? selectedButtonClasses
              : unselectedButtonClasses
          }`}
          onClick={() => handleSelection(type, buttonContent)}
        >
          {buttonContent.text}
        </button>
      ))}
    </div>
  )

  useEffect(() => {
    if (data.Zonwering?.text === "Ja") {
      dispatch(
        fillWkbData({
          ...data,
          Zonwering: {
            ...data.Zonwering,
            values: { zta: 0.3 },
          },
        })
      )
    } else {
      dispatch(
        fillWkbData({
          ...data,
          Zonwering: {
            ...data.Zonwering,
            values: { zta: 0.55 },
          },
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.Zonwering?.text])

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
      <div>
        <MediumHeadline
          text="Gevel"
          subtext="In dit blad voer je de eigenschappen van de gevel in. Met het glaspercentage
wordt de verhouding tussen het oppervlak van de ramen (inclusief kozijn) t.o.v.
het oppervlak van de buitengevel bedoeld. Vervolgens bepaal je de
isolatiewaardes van het glas en de gevel. Er wordt geen rekening gehouden
met vloeren of daken in de thermische schil. Op basis van de luchtdichtheid
(qv10-waarde) en de gebouwhoogte (hoogte van de bovenste verdiepingsvloer
t.o.v. het maaiveld) wordt de infiltratie van de gevel bepaald. Tot slot bepaal je
met de keuze voor wel/geen buitenzonwering (automatisch gestuurde screens)
en wel/geen zonwerend glas de zonwerendheid van de ramen."
          shortText="Voer hier de belangrijkste gegevens van de schil van het gebouw in. De schil heeft veel invloed op transmissie en externe koellast. "
          info
        />
        <div className="flex flex-col">
          <HorizontalStepper
            stepItems={glaspercentage}
            topic="Glaspercentage"
            wkbCheck
          />
          <HorizontalStepper stepItems={glas} topic="Glas" wkbCheck />
          <HorizontalStepper stepItems={isolatie} topic="Isolatie" wkbCheck />
          <HorizontalStepper
            stepItems={luchtdichtheid}
            topic="Luchtdichtheid"
            wkbCheck
          />
          <HorizontalStepper
            stepItems={gebouwhoogte}
            topic="Gebouwhoogte"
            wkbCheck
          />
        </div>

        <div className="flex flex-row w-full mt-20 place-items-center">
          <p className="text-h4 font-bold text-darkBlue font-mulish w-32">
            Zonwering
          </p>
          {renderButtonGroup(zonwering, data.Zonwering, "Zonwering")}
        </div>

        <div className="flex flex-row w-full mt-8 place-items-center">
          <p className="text-h4 font-bold text-darkBlue font-mulish w-32">
            Glas zonwerend
          </p>
          {renderButtonGroup(
            glasZonwerend,
            data.GlasZonwerend,
            "GlasZonwerend"
          )}
        </div>

        <div className="mt-24 pb-32">
          <Button
            btnStyling="w-full flex items-center justify-center py-3 px-2 cursor-pointer my-16"
            text={"NAAR GEBRUIK"}
            icon={ArrowRight}
            onClick={() => {
              onChangeStep(4)
              document
                .getElementById("flowBodySection")
                ?.scrollIntoView({ behavior: "smooth" })
            }}
          />
        </div>
      </div>
      <ThermalBalanceTables />
    </div>
  )
}

export default GevelSection

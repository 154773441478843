import React, { useState } from "react"
// import { useHistory } from "react-router"
// import Input from "components/Input"
// import { fillData } from "redux/actions"
import InfoModal from "components/InfoModal"
import Button from "components/Button"
import ArrowRight from "assets/images/arrow-right.svg"
import CompactheidCard from "components/CompactheidCard"
import Zeercompact from "../../assets/images/zeercompact.png"
import Redeljik from "../../assets/images/redeljik.png"
import Gemiddeld from "../../assets/images/gemiddeld.png"
import Minder from "../../assets/images/minder.png"
import Niet from "../../assets/images/niet.png"
import HorizontalStepper from "components/GebouwSection/HorizontalStepper"
import InfoIcon from "assets/images/info.png"
//images
import GebouwImage from "assets/images/Compactheid-popup-image.png"
import LuchtdichtheidImage from "assets/images/Luchtdichtheid-popup-image.png"
import IssolatieImage from "assets/images/Isolatie-popup-image.png"

const PLACEHOLDER_DATA = [
  {
    id: 1,
    title: "Niet compact",
    subtitle: "Avl / Ag = 1,6",
    image: Niet,
    values: {
      ep1: 1.375,
      ep2: 1.4737,
      ep3: 1.2188,
    },
  },
  {
    id: 2,
    title: "Minder compact",
    subtitle: "Avl / Ag = 1,4",
    image: Minder,
    values: {
      ep1: 1.25,
      ep2: 1.3158,
      ep3: 1.1563,
    },
  },
  {
    id: 3,
    title: "Gemiddeld",
    subtitle: "Avl / Ag = 1,2",
    image: Gemiddeld,
    values: {
      ep1: 1.125,
      ep2: 1.1579,
      ep3: 1.0938,
    },
  },
  {
    id: 4,
    title: "Redelijk compact",
    subtitle: "Avl / Ag = 1,0",
    image: Redeljik,
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 5,
    title: "Zeer compact",
    subtitle: "Avl / Ag = 0,8",
    image: Zeercompact,
    values: {
      ep1: 0.875,
      ep2: 0.8772,
      ep3: 0.8438,
    },
  },
]
const stepItems = [
  {
    id: 0,
    value: "Slecht",
    secondaryValue: "qv;10 = 1,2",
    values: {
      ep1: 1.1528,
      ep2: 1.1228,
      ep3: 1.0938,
    },
  },
  {
    id: 1,
    value: "Matig",
    secondaryValue: "qv;10 = 0,9",
    values: {
      ep1: 1.0972,
      ep2: 1.0702,
      ep3: 1.0625,
    },
  },
  {
    id: 2,
    value: "Basis",
    secondaryValue: "qv;10 = 0,6",
    values: {
      ep1: 1.0417,
      ep2: 1.0351,
      ep3: 1.0313,
    },
  },
  {
    id: 3,
    value: "Goed",
    secondaryValue: "qv;10 = 0,4",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 4,
    value: "Uitstekend",
    secondaryValue: "qv;10 = 0,15",
    values: {
      ep1: 0.9444,
      ep2: 0.9649,
      ep3: 0.9375,
    },
  },
]
const stepItems1 = [
  {
    id: 0,
    value: "70%",
    values: {
      ep1: 1.1528,
      ep2: 1.1053,
      ep3: 1.0625,
    },
  },
  {
    id: 1,
    value: "60%",
    values: {
      ep1: 1.0694,
      ep2: 1.0526,
      ep3: 1.0313,
    },
  },
  {
    id: 2,
    value: "50%",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 3,
    value: "40%",
    values: {
      ep1: 0.9167,
      ep2: 0.9649,
      ep3: 0.9375,
    },
  },
  {
    id: 4,
    value: "30%",
    values: {
      ep1: 0.8472,
      ep2: 0.9123,
      ep3: 0.875,
    },
  },
]
const stepItems2 = [
  {
    id: 0,
    value: "Enkelglas",
    secondaryValue: "U = 5,4",
    values: {
      ep1: 1.9583,
      ep2: 1.7895,
      ep3: 1.4063,
    },
  },
  {
    id: 1,
    value: "Dubbelglas",
    secondaryValue: "U = 3,4",
    values: {
      ep1: 1.4167,
      ep2: 1.3509,
      ep3: 1.25,
    },
  },
  {
    id: 2,
    value: "HR",
    secondaryValue: "U = 2,2",
    values: {
      ep1: 1.1111,
      ep2: 1.1053,
      ep3: 1.0938,
    },
  },
  {
    id: 3,
    value: "HR++",
    secondaryValue: "U = 1,6",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 4,
    value: "Triple",
    secondaryValue: "U = 1,4",
    values: {
      ep1: 0.9306,
      ep2: 0.9474,
      ep3: 0.9063,
    },
  },
  {
    id: 5,
    value: "Passief",
    secondaryValue: "U = 1,1",
    values: {
      ep1: 0.8611,
      ep2: 0.9123,
      ep3: 0.8125,
    },
  },
]
const stepItems3 = [
  {
    id: 0,
    value: "Ongeïsoleerd",
    secondaryValue: "Rc Gevel = 0,43",
    tertiaryValue: "Rc Dak = 0,86",
    values: {
      ep1: 1.4167,
      ep2: 1.3509,
      ep3: 1.25,
    },
  },
  {
    id: 1,
    value: "Slecht",
    secondaryValue: "Rc Gevel = 1,3",
    tertiaryValue: "Rc Dak = 1,3",
    values: {
      ep1: 1.1389,
      ep2: 1.1228,
      ep3: 1.0938,
    },
  },
  {
    id: 2,
    value: "Gemiddeld",
    secondaryValue: "Rc Gevel = 2,5",
    tertiaryValue: "Rc Dak = 2,5",
    values: {
      ep1: 1.0417,
      ep2: 1.0526,
      ep3: 1.0313,
    },
  },
  {
    id: 3,
    value: "Goed",
    secondaryValue: "Rc Gevel = 3,5",
    tertiaryValue: "Rc Dak = 3,5",
    values: {
      ep1: 1.0139,
      ep2: 1.0175,
      ep3: 1.0,
    },
  },
  {
    id: 4,
    value: "Nieuwbouw",
    secondaryValue: "Rc Gevel = 4,7",
    tertiaryValue: "Rc Dak = 6,3",
    values: {
      ep1: 1.0,
      ep2: 1.0,
      ep3: 1.0,
    },
  },
  {
    id: 5,
    value: "Passief",
    secondaryValue: "Rc Gevel = 4,7",
    tertiaryValue: "Rc Dak = 6,3",
    values: {
      ep1: 0.9722,
      ep2: 1.0,
      ep3: 0.9688,
    },
  },
]
const GebouwSection = ({ onChangeStep }) => {
  // const data = useSelector((state) => state.data)
  // const [value, setValue] = useState(50)
  // const [min, setMin] = useState(30)
  // const [max, setMax] = useState(70)
  // const handleChange = (event) => {
  //   setValue(event.target.value)
  // }
  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalText, setInfoModalText] = useState("")
  const [infoModalTitle, setInfoModalTitle] = useState("")
  const [infoModalImage, setInfoModalImage] = useState(null)
  return (
    <div className="mt-8">
      <InfoModal
        open={modalOpen}
        text={infoModalText}
        title={infoModalTitle}
        image={infoModalImage}
        closeModal={() => setModalOpen(false)}
      />
      <div className="flex items-center place-items-center">
        <p className="text-h4 font-bold text-darkBlue">Compactheid</p>
        <img
          src={InfoIcon}
          className="h-5 cursor-pointer ml-2 mt-2"
          alt="info"
          onClick={() => {
            setInfoModalText(
              "De compactheid betreft de verhouding tussen het verliesoppervlak (Als) en het gebruiksoppervlak (Ag) van het gebouw. Een lage compactheid geeft de beste energieprestatie, er is dan relatief weinig verliesoppervlak. In onderstaande afbeeldingen zijn een aantal voorbeelden van de compactheid gegeven. U bepaalt de compactheid door het totale oppervlak van de thermische schil (gevels, daken en vloer) te delen door het totale gebruiksoppervlak van het gebouw."
            )
            setInfoModalTitle("Compactheid")
            setInfoModalImage(GebouwImage)
            setModalOpen(true)
          }}
        />
      </div>
      <CompactheidCard dataResposne={PLACEHOLDER_DATA} />
      <div className="flex flex-col mt-4">
        <HorizontalStepper
          stepItems={stepItems}
          topic="Luchtdichtheid"
          setInfoModalText={setInfoModalText}
          setInfoModalTitle={setInfoModalTitle}
          setModalOpen={setModalOpen}
          text="De luchtdichtheid van de gebouwschil wordt uitgedrukt in de zogenaamde qv10-waarde. Dit is de specifieke luchtdoorlatendheid bij een uniform drukverschil van 10 Pa uitgedrukt in dm3/s per m2 gebruiksoppervlak. Een lage qv10-waarde zorgt ervoor dat er 's winters weinig koude lucht door kieren en naden het gebouw kan binnenkomen. Een qv10-waarde van 0,15 of lager betekent een uitstekende luchtdichtheid en dus de beste energieprestatie. De qv10-waarde mag alleen worden ingevuld als deze ook daadwerkelijk gemeten is. Indien de qv10-waarde niet bekend is dan wordt deze bepaald aan de hand van het bouwjaar van de gevel. "
          setInfoModalImage={setInfoModalImage}
          image={LuchtdichtheidImage}
        />
        <HorizontalStepper
          stepItems={stepItems1}
          topic="Glaspercentage"
          setInfoModalText={setInfoModalText}
          setInfoModalTitle={setInfoModalTitle}
          setModalOpen={setModalOpen}
          text="Onder het glaspercentage wordt de verhouding tussen het oppervlak aan ramen en het totale geveloppervlak verstaan. Een totaal geveloppervlak van 120 m2 waarin 30 ramen van ieder 2,0 m2 zijn opgenomen, geeft een glaspercentage van 50%. Het oppervlak van zowel het kozijn als het glas vormen hierbij samen het oppervlak van het raam. Een lager glaspercentage leidt tot een betere energieprestatie."
          setInfoModalImage={setInfoModalImage}
          image={null}
        />
        <HorizontalStepper
          stepItems={stepItems2}
          topic="Glastype"
          setInfoModalText={setInfoModalText}
          setInfoModalTitle={setInfoModalTitle}
          setModalOpen={setModalOpen}
          // text="Onder de U-waarde van het raam wordt de gemiddelde warmtedoorgangscoëfficiënt van het raam verstaan. Hierin zijn zowel de U-waarde van het kozijn als het glas vertegenwoordigd. Een lage U-waarde leidt tot lage warmteverliezen en een goede energieprestatie.
          // De volgende verschillende raamtypen zijn opgenomen in de tool:
          // Triple glas in houten of kunststof kozijn : Uraam = 1,4 W/(m2.K)
          // HR++ glas in houten of kunststof kozijn : Uraam = 1,7 W/(m2.K)
          // HR glas in houten of kunststof kozijn : Uraam = 2,2 W/(m2.K)	[alleen bestaande bouw]
          // Dubbel glas in metalen kozijn : Uraam = 3,4 W/(m2.K)		[alleen bestaande bouw]
          // Enkel glas in metalen kozijn : Uraam = 5,4 W/(m2.K)		[alleen bestaande bouw]"
          text={
            <>
              <p>
                Onder de U-waarde van het raam wordt de gemiddelde
                warmtedoorgangscoëfficiënt van het raam verstaan. Hierin zijn
                zowel de U-waarde van het kozijn als het glas vertegenwoordigd.
                Een lage U-waarde leidt tot lage warmteverliezen en een goede
                energieprestatie.
              </p>
              <p>
                De volgende verschillende raamtypen zijn opgenomen in de tool:
              </p>
              <ul>
                <li>
                  - Triple glas in houten of kunststof kozijn: Uraam = 1,4
                  W/(m2.K)
                </li>
                <li>
                  - HR++ glas in houten of kunststof kozijn: Uraam = 1,7
                  W/(m2.K)
                </li>
                <li>
                  - HR glas in houten of kunststof kozijn: Uraam = 2,2 W/(m2.K)
                  (alleen bestaande bouw)
                </li>
                <li>
                  - Dubbel glas in metalen kozijn: Uraam = 3,4 W/(m2.K) (alleen
                  bestaande bouw)
                </li>
                <li>
                  - Enkel glas in metalen kozijn: Uraam = 5,4 W/(m2.K) (alleen
                  bestaande bouw)
                </li>
              </ul>
            </>
          }
          setInfoModalImage={setInfoModalImage}
          image={null}
        />
        <HorizontalStepper
          stepItems={stepItems3}
          topic="Isolatie schil"
          setInfoModalText={setInfoModalText}
          setInfoModalTitle={setInfoModalTitle}
          setModalOpen={setModalOpen}
          text="De isolatiewaarde (Rc-waarde) van de gevel, het dak en de laagste vloer zijn van belang voor het warmteverlies door de thermische schil van het gebouw. Afhankelijk van het bouwjaar van het gebouw zijn er minimale Rc-waardes volgens het bouwbesluit van kracht. Een hoge Rc-waarde leidt tot lage warmteverliezen en een goede energieprestatie. De volgende Rc-waardes zijn aangehouden:"
          setInfoModalImage={setInfoModalImage}
          image={IssolatieImage}
        />
        <Button
          btnStyling="w-full flex items-center justify-center py-3 px-2 cursor-pointer my-16"
          text={"NAAR INSTALLATIES"}
          icon={ArrowRight}
          onClick={() => {
            onChangeStep(3)
            document
              .getElementById("flowBodySection")
              ?.scrollIntoView({ behavior: "smooth" })
          }}
        />
      </div>
    </div>
  )
}
export default GebouwSection

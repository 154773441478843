import React from "react"
import Button from "components/Button"
import { useDispatch } from "react-redux"
import ArrowRight from "assets/images/arrow-right.svg"
import { useHistory } from "react-router"
import { changeActiveTool } from "redux/actions"

const posts = [
  {
    id: 1,
    title: "Doe de Binnenklimaatcheck",
    description: "...comfortprestaties van mijn installatie controleren",
    buttonText: "Doe de Binnenklimaatcheck",
    href: "/",
    mainTitle: "Binnenklimaatcheck",
    subtitle: "Controleer het binnenklimaat van jouw ruimte",
    navigation: "/binnenklimaat/projectgegevens",
  },
  {
    id: 2,
    title: "BENG (nieuwbouw)",
    description: "...de energieprestatie van een nieuw gebouw bepalen",
    buttonText: "Doe de BENGcheck",
    mainTitle: "BENGcheck",
    subtitle: "Bereken snel indicatieve BENG scores",
    href: "/",
    navigation: "/BENGcheck/projectgegevens",
  },
  {
    id: 3,
    title: "Energielabel (bestaande bouw)",
    description: "...de energieprestatie van een bestaand gebouw bepalen",
    buttonText: "Doe de Energielabelcheck",
    mainTitle: "Energielabelcheck",
    subtitle: "Bereken snel een indicatief energielabel",
    href: "/",
    navigation: "/energielabelcheck/projectgegevens",
  },
  {
    id: 4,
    title: "WKB check",
    description: "...de warmte-koude balans van mijn ruimte controleren",
    buttonText: "Doe de WKBcheck",
    mainTitle: "WKB check",
    subtitle: "de warmte-koude balans van mijn ruimte controleren",
    href: "/",
    navigation: "/wkbcheck/projectgegevens",
  },
]

export default function InformationToolCard() {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <div>
      <div className="w-full">
        <h2
          className="text-h2 font-bold text-lightBlue w-full text-center"
          style={{ marginTop: "50px" }}
        >
          Ik wil...
        </h2>
        <div className="grid w-full gap-y-6 gap-x-4 sm:mt-4 lg:mx-0 lg:max-w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {posts.map((post) => (
            <div
              key={post.id}
              className="flex max-w-sm flex-col bg-white justify-between shadow-lg rounded-xl p-5"
            >
              <div className="w-full">
                <p className="leading-6 text-lightBlue font-bold">
                  {post.description}
                </p>
              </div>
              <div className="mt-5 w-full">
                <Button
                  text={post.buttonText}
                  btnStyling={"w-full flex py-3 px-2 cursor-pointer"}
                  textSize="text-h5"
                  isDark={false}
                  icon={ArrowRight}
                  iconStyle={"h-4 w-4 mr-0"}
                  onClick={() => {
                    dispatch(
                      changeActiveTool(
                        post.mainTitle,
                        post.navigation,
                        post.subtitle,
                        post?.id
                      )
                    )
                    history.push(post.navigation)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

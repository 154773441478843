import React from "react"

const WarningMessage = ({ title, desc }) => {
  return (
    <div className="flex flex-col gap-2 bg-red-100 p-4 rounded-md text-h5 my-3">
      <p className="text-red-600 font-bold">{title}</p>
    </div>
  )
}

export default WarningMessage

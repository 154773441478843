import React from "react"
import { useDispatch } from "react-redux"
import { resetWkbData } from "redux/actions"
import Button from "components/Button"
import ResetIcon from "assets/images/reset-icon.svg"

const Stepper = ({ step, onChangeStep, steps, resetButton }) => {
  const dispatch = useDispatch()

  const handleReset = () => {
    window.location.reload()

    setTimeout(() => {
      dispatch(resetWkbData())
      onChangeStep(1)
    }, 300)
  }

  return (
    <div className="flex flex-col md:flex-row w-full justify-between items-center mx-0 xl:mx-16 my-6">
      <div className="pl-5 pr-8 w-full sm:4/5 md:w-2/3 lg:w-500">
        <div className="mb-4 pb-4">
          <div className="flex items-center">
            {steps?.map((label, index) => {
              const stepIndex = index + 1
              return (
                <React.Fragment key={stepIndex}>
                  <div
                    className="flex items-center relative cursor-pointer"
                    onClick={() => onChangeStep(stepIndex)}
                  >
                    <div
                      className={`rounded-full h-8 w-8 py-1 ${
                        step >= stepIndex ? "bg-lightBlue" : "bg-gray"
                      }`}
                    >
                      <p className="text-white font-bold text-center text-h4">
                        {stepIndex}
                      </p>
                    </div>
                    <div
                      className={`absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-semibold  ${
                        step >= stepIndex ? "text-lightBlue" : "text-gray"
                      } `}
                    >
                      {label}
                    </div>
                  </div>
                  {stepIndex < steps.length && (
                    <div
                      className={`flex-auto border-t-4 transition duration-500 ease-in-out ${
                        step > stepIndex ? "border-lightBlue" : "border-gray"
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
      <div className="mt-10 md:mt-4">
        {resetButton && (
          <div className="text-right">
            <Button
              btnStyling="w-60 flex items-center justify-center py-3 px-2 cursor-pointer"
              text={"Opnieuw invoeren"}
              icon={ResetIcon}
              onClick={handleReset}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Stepper

import {
  calculateAirHeatingFancil,
  calculateAirHeatingPlafond,
  calculateAirHeatingVAV,
  calculateLocalCoolingPlafond,
  calculateLocalFancil,
  calculateVentilation,
} from "./installatiesCalculations"

export function getKlimaatplafondsValues(floorArea, selectedVentilation) {
  return {
    heating: {
      classC: { radiation: 86, air: 0 },
      classB: { radiation: 82, air: 0 },
      classA: { radiation: 79, air: 0 },
    },
    cooling: {
      classC: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 10),
        local: 56,
      },
      classB: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 9),
        local: 50,
      },
      classA: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 8),
        local: 44,
      },
    },
  }
}

export function getPlafondInductieValues(floorArea, selectedVentilation) {
  return {
    heating: {
      classC: {
        radiation: 0,
        air: calculateAirHeatingPlafond(selectedVentilation, floorArea),
      },
      classB: {
        radiation: 0,
        air: calculateAirHeatingPlafond(selectedVentilation, floorArea),
      },
      classA: {
        radiation: 0,
        air: calculateAirHeatingPlafond(selectedVentilation, floorArea),
      },
    },
    cooling: {
      classC: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 10),
        local: calculateLocalCoolingPlafond(selectedVentilation, floorArea, 10),
      },
      classB: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 9),
        local: calculateLocalCoolingPlafond(selectedVentilation, floorArea, 9),
      },
      classA: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 8),
        local: calculateLocalCoolingPlafond(selectedVentilation, floorArea, 8),
      },
    },
  }
}
export function getVAVValues(floorArea, selectedVentilation) {
  return {
    heating: {
      classC: {
        radiation: 0,
        air: calculateAirHeatingVAV(selectedVentilation, floorArea),
      },
      classB: {
        radiation: 0,
        air: calculateAirHeatingVAV(selectedVentilation, floorArea),
      },
      classA: {
        radiation: 0,
        air: calculateAirHeatingVAV(selectedVentilation, floorArea),
      },
    },
    cooling: {
      classC: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 10),
        local: 0,
      },
      classB: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 9),
        local: 0,
      },
      classA: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 8),
        local: 0,
      },
    },
  }
}
export function getFancoilUnitsValues(
  floorArea,
  selectedVentilation,
  floorHeight
) {
  return {
    heating: {
      classC: { radiation: 0, air: calculateAirHeatingFancil(floorHeight) },
      classB: { radiation: 0, air: calculateAirHeatingFancil(floorHeight) },
      classA: { radiation: 0, air: calculateAirHeatingFancil(floorHeight) },
    },
    cooling: {
      classC: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 10),
        local: calculateLocalFancil(
          floorHeight,
          selectedVentilation,
          floorArea,
          10
        ),
      },
      classB: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 9),
        local: calculateLocalFancil(
          floorHeight,
          selectedVentilation,
          floorArea,
          9
        ),
      },
      classA: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 8),
        local: calculateLocalFancil(
          floorHeight,
          selectedVentilation,
          floorArea,
          8
        ),
      },
    },
  }
}
export function getVloerverwarmingValues(floorArea, selectedVentilation) {
  return {
    heating: {
      classC: { radiation: 86, air: 0 },
      classB: { radiation: 82, air: 0 },
      classA: { radiation: 79, air: 0 },
    },
    cooling: {
      classC: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 10),
        local: 28,
      },
      classB: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 9),
        local: 25,
      },
      classA: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 8),
        local: 22,
      },
    },
  }
}

export function getRadiatorenValues(floorArea, selectedVentilation) {
  return {
    heating: {
      classC: {
        radiation: 200,
        air: 0,
      },
      classB: {
        radiation: 200,
        air: 0,
      },
      classA: {
        radiation: 200,
        air: 0,
      },
    },
    cooling: {
      classC: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 10),
        local: 0,
      },
      classB: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 9),
        local: 0,
      },
      classA: {
        ventilation: calculateVentilation(selectedVentilation, floorArea, 8),
        local: 0,
      },
    },
  }
}

import React from "react"
import "./App.css"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Provider } from "react-redux"

// import Home from "./pages/Home"
import Tools from "pages/Tools"
import Wizard from "./pages/Wizard"
import BENGWizard from "./pages/BENGWizard"
import OriginalWizard from "./pages/OriginalWizard"
import WKBcheck from "./pages/WKBcheck/WKBcheck"
import store from "redux/store"

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/* <Route path="/tools" component={Tools} /> */}
          <Route path="/binnenklimaat" component={OriginalWizard} />
          <Route path="/BENGcheck" component={BENGWizard} />
          <Route path="/energielabelcheck" component={Wizard} />
          <Route path="/WKBcheck" component={WKBcheck} />
          <Route path="/" component={Tools} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App

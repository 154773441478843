export function getGlassArea(
  width,
  ceilingheight,
  space,
  glasspercentage,
  depth
) {
  const result =
    width * ceilingheight * space.wall1 * glasspercentage +
    depth * ceilingheight * space.wall2 * glasspercentage

  return result
}

export function getWallArea(
  width,
  ceilingheight,
  space,
  glasspercentage,
  depth
) {
  const result =
    width * ceilingheight * space.wall1 * (1 - glasspercentage) +
    depth * ceilingheight * space.wall2 * (1 - glasspercentage)

  return result
}

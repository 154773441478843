import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import Button from "components/Button"
import ArrowRight from "assets/images/arrow-right.svg"
import HorizontalStepper from "components/BENGGebouwSection/HorizontalStepper"
import ThermalBalanceTables from "components/Tables/ThermalBalanceTables"
import MediumHeadline from "components/MediumHeadline"
import { fillWkbData } from "redux/actions"
import { verlichting } from "./data"

const GebruikSection = ({ onChangeStep }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.wkbData)
  const [persons, setPersons] = useState(data?.persons || 2)

  const handleBezettingChange = (e) => {
    const value = e.target.value === "" ? "" : parseInt(e.target.value, 10)
    setPersons(value)

    if (e.target.value === "") {
      dispatch(fillWkbData({ ...data, persons: 2 }))
    } else if (!isNaN(value)) {
      dispatch(fillWkbData({ ...data, persons: value }))
    }
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
      <div className="flex flex-col">
        <MediumHeadline
          text="Interne warmtebelasting"
          subtext="In dit blad voer je de interne warmtebelasting in de zomersituatie in. Voor de
personen wordt uitgegaan van standaard kantoorwerkzaamheden en
zomerkleding. De verlichting en apparatuur bestaat in een gemiddeld modern
kantoor uit circa 5 W/m² aan LED-verlichting en circa 10 W/m² aan
energiezuinige gebruiksapparatuur. Voor een meer of minder intensieve interne
belasting kan een lagere of hogere waarde worden gekozen."
          shortText="Geef met de onderstaande kenmerken aan hoe de ruimte wordt gebruikt. Deze kenmerken hebben invloed op de interne koellast van de ruimte."
          info
        />
        <div className="flex flex-row w-full mt-8 place-items-center">
          <p className="text-h4 font-bold text-darkBlue font-mulish mr-8">
            Bezetting
          </p>
          <input
            type="number"
            className="w-24 border-2 ml-44 text-lightBlue font-bold border-buttonGray h-10 rounded-lg px-2 text-center"
            value={persons}
            onChange={handleBezettingChange}
            min="0"
            step="1"
          />
          <p className="text-h5 text-greyWarning font-mulish w-32 ml-6">
            Personen
          </p>
        </div>
        <div className="flex flex-col mt-8">
          <HorizontalStepper
            stepItems={verlichting}
            topic="Verlichting"
            wkbCheck
          />
        </div>

        <div className="mt-24 pb-32">
          <Button
            btnStyling="w-full flex items-center justify-center py-3 px-2 cursor-pointer my-16"
            text={"NAAR INSTALLATIES"}
            icon={ArrowRight}
            onClick={() => {
              onChangeStep(5)
              document
                .getElementById("flowBodySection")
                ?.scrollIntoView({ behavior: "smooth" })
            }}
          />
        </div>
      </div>
      <ThermalBalanceTables />
    </div>
  )
}

export default GebruikSection

import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import ErrorAlert from "components/ErrorAlert"
import Stepper from "components/Stepper"
import AlgemeenSection from "components/AlgemeenSection"
import GeometrySection from "pages/WKBcheck/GeometrySection"
import GevelSection from "pages/WKBcheck/GevelSection"
import GebruikSection from "pages/WKBcheck/GebruikSection"
import WKBInstallatiesSection from "pages/WKBcheck/WKBInstallatiesSection"
import { changeActiveTool, fillWkbData } from "redux/actions"
import Header from "components/Header"
import Container from "components/Container"
import Resultaat from "./Resultaat"

const sections = [
  AlgemeenSection,
  GeometrySection,
  GevelSection,
  GebruikSection,
  WKBInstallatiesSection,
  Resultaat,
]

const WKBcheck = ({ setPage }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.wkbData)

  const [currentStep, setCurrentStep] = useState(1)
  const [email, setEmail] = useState(true)
  const [customErrorText, setCustomErrorText] = useState(null)
  const [isCalculated, setIsCalculated] = useState(false)
  const [isError, setIsError] = useState(false)

  const isEmailValid = () => {
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regEmail.test(data.Email)
  }

  const isGeometryInputValid = () => {
    if (data.ceilingHeight > 4) return false
    if (data.ceilingHeight > data.floorHeight) return false
    return true
  }

  const handleStepChange = (step) => {
    if (step === 1) {
      setCurrentStep(step)
      dispatch(fillWkbData({ ...data, currPage: step }))
    } else if (step === 2) {
      if (data.Email === "" || !isEmailValid()) {
        setCustomErrorText("Deze informatie is verplicht.")
        setEmail(false)
        setIsError(true)
      } else {
        setIsError(false)
        setIsCalculated(true)
        setCurrentStep(step)
        dispatch(fillWkbData({ ...data, currPage: step }))
      }
    } else if (isCalculated && step >= 3 && step <= 6) {
      if (step === 3) {
        const isValid = isGeometryInputValid()
        if (!isValid) return
      }
      setCurrentStep(step)
      dispatch(fillWkbData({ ...data, currPage: step }))
      setIsError(false)
    }
  }

  useEffect(() => {
    dispatch(changeActiveTool("WKBcheck", "/", "WKB tool", 4))
  }, [dispatch])

  useEffect(() => console.log(currentStep), [currentStep])

  const CurrentSection = sections[currentStep - 1] || AlgemeenSection

  return (
    <>
      <Header
        title="WKB check"
        desc="Controleer de warmte-koude balans van jouw ruimte"
      />
      <div className="flex items-center px-4 md:px-8 bg-lightGray pb-4">
        <Stepper
          step={currentStep}
          onChangeStep={handleStepChange}
          steps={[
            "Algemeen",
            "Geometrie",
            "Gevel",
            "Gebruik",
            "Installaties",
            "Resultaat",
          ]}
          resetButton
        />
      </div>
      <Container className="px-4 md:px-8 lg:px-16">
        <div id="flowBodySection">
          <ErrorAlert
            open={isError}
            setIsError={setIsError}
            errorText={customErrorText}
          />
          <CurrentSection
            email={email}
            setEmail={setEmail}
            onChangeStep={handleStepChange}
            wkbcheck
            buttonText="NAAR GEOMETRIE"
          />
          <div className="relative">
            <p
              className="bottom-0 right-0 absolute ml-20 mb-4 text-gray text-xs"
              style={{ fontSize: "12px" }}
            >
              Deze tool is ontwikkeld door{" "}
              <a
                href="https://renor.nl/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline-none"
              >
                Renor
              </a>
            </p>
          </div>
        </div>
      </Container>
    </>
  )
}

export default WKBcheck

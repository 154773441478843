import React from "react"
import Container from "components/Container"
import Header from "components/Header"
import HeaderFooterStripes from "components/HeaderFooterStripes"
import InformationToolCard from "components/InformationToolCard/InformationToolCard"

const Tools = () => {
  const openMailer = () => {
    window.open(
      "mailto:info@tvvl.nl?subject=Contactaanvraag klimaatinstallatiecheck.nl&body="
    )
  }

  return (
    <div className="h-full font-mulish">
      <Header />
      <HeaderFooterStripes small />
      <div className="flex h-full">
        <div className="lg:w-[100vh] laptop:pb-16 w-[100vh]">
          <Container>
            <InformationToolCard />

            <div className="mt-8 max-w-6xl pt-4 px-5">
              <p className="font-bold text-h3 md:text-h2 text-lightBlue laptop:text-h3 pt-0">
                Over ons
              </p>
              <p className="pt-2 text-h6 md:text-base">
                Op deze website bieden we je eenvoudige tools aan om de
                systeemkeuze van een klimaatinstallatie te controleren. De tools
                zijn opgesteld om enerzijds de comfortprestatie en anderzijds de
                energieprestatie van een klimaatinstallatie in een kantoorgebouw
                snel inzichtelijk te kunnen maken. De thermische schil en
                installaties van het gebouw spelen hierbij een belangrijke rol.
                De tools hebben als doel om snel een indicatie van de technische
                haalbaarheid van een gebouwontwerp te kunnen geven.
              </p>
              <p className="pt-4 text-h6 md:text-base">
                Klimaatinstallatiecheck.nl is ontwikkeld vanuit het Platform
                Binnenklimaattechniek. Dit platform is een initiatief van
                Binnenklimaat Nederland en TVVL.
              </p>
            </div>

            <div className="my-8 max-w-xl bg-gray py-4 px-5">
              <p className="font-bold text-h3 md:text-h2 text-orange laptop:text-h3 pt-0">
                Meer informatie
              </p>
              <p className="pt-2 text-h6 md:text-base">
                Voor vragen over deze tools kunt u contact opnemen met TVVL.
                Vanuit hier zal uw vraag worden doorgestuurd naar de
                desbetreffende ontwikkelaar.
              </p>
              <p className="pt-3 text-h6 md:text-base">
                Mail naar{" "}
                <span
                  className="font-bold text-lightBlue cursor-pointer"
                  onClick={openMailer}
                >
                  info@tvvl.nl
                </span>
              </p>
            </div>
            <div className="max-w-xl">
              <p className="text-gray text-xs" style={{ fontSize: "14px" }}>
                Deze site valt onder het{" "}
                <a
                  href="https://tvvl.nl/privacy-policy-tvvl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 underline-none"
                >
                  TVVL Privacybeleid
                </a>
              </p>
            </div>
          </Container>
        </div>
        <div className="invisible lg:visible lg:w-2/5 h-auto bg-home-background bg-cover bg-no-repeat" />
      </div>
      <HeaderFooterStripes />
    </div>
  )
}

export default Tools

import React from "react"

import WinterThermalBalanceTable from "./WinterThermalBalanceTable"
import SummerThermalBalanceTable from "./SummerThermalBalanceTable"

const ThermalBalanceTables = () => (
  <div className="w-full md:4/6 lg:w-4/6 ml-auto mb-12 md:mb-0 lg:mb-0">
    <WinterThermalBalanceTable />
    <SummerThermalBalanceTable />
  </div>
)

export default ThermalBalanceTables

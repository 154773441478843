import Tooltip from "components/Tooltip"
import React from "react"
import InfoIcon from "assets/images/info.png"

const MediumHeadline = ({ text, subtext, shortText, info }) => {
  return (
    <div className="pt-8 relative">
      <div className="flex relative items-center gap-4">
        <h3 className="text-darkBlue font-bold text-h2 break-words">{text}</h3>
        {info && (
          <img
            src={InfoIcon}
            data-tip={subtext}
            className="cursor-pointer right-0 h-5"
            alt="info"
          />
        )}
        <Tooltip />
      </div>

      <p className="text-12 font-light">{shortText}</p>
    </div>
  )
}

export default MediumHeadline
